<div class="entity-container">
	<h1>Fields</h1>

	<div class="entity-actions">
		<button (click)="newEntity()" mat-raised-button [disabled]="!(loaded$ | async)">New Field</button>

		<button (click)="refresh()" mat-mini-fab color="primary" aria-label="Refresh data">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>

	<div>
		<mat-form-field>
			<mat-label>Filter Name</mat-label>
			<input matInput (keyup)="nameFilterChange($event)" placeholder="" #input />
		</mat-form-field>
		<mat-form-field *ngFor="let filter of filterSelectObj" style="margin-left: 15px">
			<mat-label>Filter {{ filter.name }}</mat-label>
			<select
				matNativeControl
				name="{{ filter.columnProp }}"
				[(ngModel)]="filter.modelValue"
				(change)="filterChange(filter, $event)">
				<option value="">-- Select {{ filter.name }} --</option>
				<option [value]="item" *ngFor="let item of filter.options">
					{{ item }}
				</option>
			</select>
		</mat-form-field>
		&nbsp;
		<button mat-flat-button color="warn" (click)="resetFilters()">Reset</button>
	</div>

	<div class="table-container">
		<div class="loading-shade" *ngIf="loading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="index">
					<th mat-header-cell *matHeaderCellDef>No.</th>
					<td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let row">{{ row.name }}</td>
				</ng-container>

				<ng-container matColumnDef="products">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Products</th>
					<td mat-cell *matCellDef="let row" [innerHTML]="showProducts(row.products)"></td>
				</ng-container>

				<ng-container matColumnDef="fieldType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Field Type</th>
					<td mat-cell *matCellDef="let row">
						{{ row.fieldType | titlecase }}
					</td>
				</ng-container>

				<ng-container matColumnDef="dataFormat">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
					<td mat-cell *matCellDef="let row">
						{{ row.dataFormat | titlecase }}
					</td>
				</ng-container>

				<ng-container matColumnDef="defaultValue">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Default Value</th>
					<td mat-cell *matCellDef="let row">{{ row.defaultValue }}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
					<td mat-cell *matCellDef="let row">{{ row.description }}</td>
				</ng-container>

				<ng-container matColumnDef="formula">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Formula/Column</th>
					<td mat-cell *matCellDef="let row">{{ row.formula }}</td>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<button (click)="edit($event, row.id)" mat-mini-fab color="primary" aria-label="Edit Transaction Field">
							<mat-icon>edit</mat-icon>
						</button>
						<button (click)="delete($event, row.id)" mat-mini-fab color="warn" aria-label="Delete Transaction Field">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filters</td>
				</tr>
			</table>
		</div>
	</div>
</div>
