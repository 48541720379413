import { APP_INITIALIZER, ErrorHandler, forwardRef, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MaterialModule } from 'src/app/material.module';
import { ApiModule } from '../.api-client/api.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './app.reducers';
import { EffectsModule } from '@ngrx/effects';

import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { ApiInterceptor } from './api.interceptor';
import { environment } from '../environments/environment';
import { UserEffects } from './user/store/user.effects';
import { ChartOfAccountsModule } from './chart-of-accounts/chart-of-accounts.module';
import { TransactionsModule } from './transactions/transactions.module';
import { SharedModule } from './shared/shared.module';
import { ToolsModule } from './tools/tools.module';
import { StatementsModule } from './statements/statements.module';

export const API_INTERCEPTOR_PROVIDER: Provider = {
	provide: HTTP_INTERCEPTORS,
	useExisting: forwardRef(() => ApiInterceptor),
	multi: true,
};

@NgModule({
	declarations: [AppComponent, DashboardComponent, UnauthorizedComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MaterialModule,
		ApiModule.forRoot({
			rootUrl: environment.apiUrl,
		}),
		AppRoutingModule,
		LayoutModule,
		AuthConfigModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
		}),

		EffectsModule.forRoot([UserEffects]),
		EntityDataModule.forRoot(entityConfig),
		SharedModule,
		ChartOfAccountsModule,
		TransactionsModule,
		ToolsModule,
		StatementsModule,
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: true,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		ApiInterceptor,
		API_INTERCEPTOR_PROVIDER,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
