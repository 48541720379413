import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DEFAULT_MODAL_WIDTH, TRANSACTION_MODEL } from '../../constants';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransactionModelService } from '../transaction-model.service';
import { TransactionModelModel } from '../store/transaction-model.model';
import { TransactionModelDialogComponent } from '../transaction-model-dialog/transaction-model-dialog.component';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-transaction-models',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './transaction-models.component.html',
	styleUrls: ['./transaction-models.component.scss'],
})
export class TransactionModelsComponent extends BaseListComponent {
	loading$ = this.transactionModelService.loading$;
	loaded$ = this.transactionModelService.loaded$;
	transactionModels$ = this.transactionModelService.entities$;
	transactionModels: TransactionModelModel[];
	displayedColumns: string[] = ['name', 'ref', 'product', 'description', 'actions'];

	constructor(
		public transactionModelService: TransactionModelService,
		dialog: MatDialog,
		snackBar: MatSnackBar,
		private router: Router
	) {
		super(transactionModelService, dialog, snackBar);
		this.dialogClass = TransactionModelDialogComponent;
		this.transactionModels$.pipe(takeUntil(this.unsubscribe$)).subscribe(transactionModels => {
			this.transactionModels = transactionModels;
			this.dataSource = new MatTableDataSource(this.transactionModels);
			this.dataSource.sort = this.sort;
		});
		this.transactionModelService.getAll();
	}

	ngOnInit() {
		this.prepareEventNotifications(TRANSACTION_MODEL, this.transactionModelService.entityActions$);
	}

	override edit($event: Event, id: string) {
		$event.stopPropagation();
		this.dialogRef = this.dialog.open(TransactionModelDialogComponent, {
			width: DEFAULT_MODAL_WIDTH,
			data: {
				transactionModel: this.transactionModels.find(tm => tm.id === id),
			},
		});
		return false;
	}

	openTransactionModel(tm: TransactionModelModel) {
		this.router.navigate(['/models', tm.id]);
	}
}
