import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { FORM_ERRORS } from './form-control-errors.enum';

export function NotZeroValidator(): ValidatorFn {
  return (fc: AbstractControl): ValidationErrors | null => {
    if (fc.value === 0) {
			return {
				[FORM_ERRORS.NOT_ZERO]: true,
			};
    } else {
      return null;
    }
  };
}
