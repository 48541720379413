<div class="entity-container">
	<mat-form-field class="investor-search">
		<mat-label>Select Investor:</mat-label>
		<input type="text" aria-label="Field" matInput [formControl]="investor" [matAutocomplete]="auto" />
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			<mat-option *ngFor="let inv of filteredOptions | async" [value]="inv">
				{{ inv.name }}
				<span class="float-right">{{ inv.id }}</span>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<table mat-table [dataSource]="data" class="table">
		<ng-container matColumnDef="index">
			<th mat-header-cell *matHeaderCellDef>No.</th>
			<td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef>Date</th>
			<td mat-cell *matCellDef="let row">
				{{ row.date | date : 'YYYY-MM-dd' }}
			</td>
			<td mat-footer-cell *matFooterCellDef>{{ dayDifference }} Days</td>
		</ng-container>

		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef>Description</th>
			<td mat-cell *matCellDef="let row">{{ row.description }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="buyRate">
			<th mat-header-cell *matHeaderCellDef>Buy Rate</th>
			<td mat-cell *matCellDef="let row" class="text-center">{{ showPercent(row.buyRate) }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="credit">
			<th mat-header-cell *matHeaderCellDef class="center">Credit</th>
			<td mat-cell *matCellDef="let row" class="text-right">
				<span *ngIf="isNumber(row.credit)">
					{{ row.credit | currency : 'EUR' : '' }}
				</span>
			</td>
			<td mat-footer-cell *matFooterCellDef class="text-right">
				{{ totals('credit') | currency : 'EUR' : '' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="debit">
			<th mat-header-cell *matHeaderCellDef class="center">Debit</th>
			<td mat-cell *matCellDef="let row" class="text-right">
				<span *ngIf="isNumber(row.debit)">-{{ row.debit | currency : 'EUR' : '' }}</span>
			</td>
			<td mat-footer-cell *matFooterCellDef class="text-right">
				{{ totals('debit') | currency : 'EUR' : '' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="yield">
			<th mat-header-cell *matHeaderCellDef class="center">Yield</th>
			<td mat-cell *matCellDef="let row" class="text-right">
				<span *ngIf="isNumber(row.yield)">
					{{ row.yield | currency : 'EUR' : '' }}
				</span>
			</td>
			<td mat-footer-cell *matFooterCellDef class="text-right">
				{{ totals('yield') | currency : 'EUR' : '' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="balance">
			<th mat-header-cell *matHeaderCellDef class="center">Balance</th>
			<td mat-cell *matCellDef="let row" class="text-right">
				<span *ngIf="isNumber(row.balance)">
					{{ row.balance | currency : 'EUR' : '' }}
				</span>
			</td>
			<td mat-footer-cell *matFooterCellDef>
				{{ lastBalance | currency : 'EUR' : '' }}
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>

	<mat-paginator
		[length]="resultsLength"
		[pageSize]="pageSize"
		aria-label="Select page of search results"></mat-paginator>
</div>
