<mat-sidenav-container class="sidenav-container">
	<mat-sidenav
		#drawer
		[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
		[mode]="(isHandset$ | async) ? 'over' : 'side'"
		[opened]="(isHandset$ | async) === false"
		class="sidenav"
		fixedInViewport>
		<mat-toolbar i18n>Menu</mat-toolbar>
		<mat-nav-list>
			<mat-list-item disabled>Transactions</mat-list-item>
			<a
				class="subitem"
				routerLink="/investor-transactions"
				routerLinkActive="active"
				[class.active]="isActive('investor-transactions')"
				mat-list-item>
				Investabill
			</a>
			<a class="subitem" mat-list-item>a-ETR</a>
			<mat-list-item disabled>Statements</mat-list-item>
			<a
				class="subitem"
				routerLink="/investor-statement"
				routerLinkActive="active"
				[class.active]="isActive('investor-statement')"
				mat-list-item>
				Investor
			</a>
			<a class="subitem" mat-list-item>a-ETR</a>
			<a routerLink="/investor-data" routerLinkActive="active" [class.active]="isActive('investor-data')" mat-list-item>
				Data
			</a>
			<a routerLink="/models" routerLinkActive="active" mat-list-item>Models</a>
			<a routerLink="/journals" routerLinkActive="active" [class.active]="isActive('journals')" mat-list-item>
				Journals
			</a>
			<a routerLink="/fields" routerLinkActive="active" mat-list-item>Fields</a>
			<a routerLink="/ledgers" routerLinkActive="active" [class.active]="isActive('ledgers')" mat-list-item>Ledgers</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar color="primary">
			<button (click)="drawer.toggle()" i18n-aria-label aria-label="Toggle sidenav" mat-icon-button type="button">
				<mat-icon i18n-aria-label aria-label="Side nav toggle icon">menu</mat-icon>
			</button>
			<span>New Credebt Test</span>
			<span class="toolbar-spacer"></span>
			<div id="account" *ngIf="isAuthenticated$ | async">
				<button mat-fab i18n-aria-label aria-label="Account button" [matMenuTriggerFor]="accountMenu">
					{{ (userData$ | async)?.initials }}
				</button>
				<mat-menu #accountMenu="matMenu">
					<button mat-menu-item disabled>
						<span>{{ (userData$ | async)?.email }}</span>
					</button>
					<button mat-menu-item (click)="logout()">
						<mat-icon i18n>logout</mat-icon>
						<span i18n>Logout</span>
					</button>
				</mat-menu>
			</div>
		</mat-toolbar>

		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
