import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';
import { JournalLine } from './transactions/store/journal-line.model';

const entityMetadata: EntityMetadataMap = {
	Ledger: {},
	Journal: {},
	TransactionModel: {},
	TransactionField: {},
	InvestorData: {},
	JournalLine: {
		filterFn: (jls: JournalLine[], { transactionModelId }) =>
			jls.filter(jl => jl.transactionModel === transactionModelId),
	},
};

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
	entityMetadata,
	pluralNames,
};
