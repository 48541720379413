import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestorStatementsComponent } from './investor-statements/investor-statements.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [InvestorStatementsComponent],
	imports: [CommonModule, FormsModule, MaterialModule, ReactiveFormsModule, HttpClientModule],
})
export class StatementsModule {}
