<div class="entity-container">
	<h1>Journals</h1>

	<div class="entity-actions">
		<button (click)="refresh()" mat-mini-fab color="primary" aria-label="Refresh data">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="" #input />
	</mat-form-field>

	<div class="table-container">
		<div class="loading-shade" *ngIf="loading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="code" matSortDirection="asc">
				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let row">{{ row.name }}</td>
				</ng-container>

				<ng-container matColumnDef="code">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
					<td mat-cell *matCellDef="let row">{{ row.code }}</td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let row">{{ row.status | titlecase }}</td>
				</ng-container>

				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
					<td mat-cell *matCellDef="let row">{{ row.type | titlecase }}</td>
				</ng-container>

				<ng-container matColumnDef="taxType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Tax Type</th>
					<td mat-cell *matCellDef="let row">{{ row.taxType | titlecase }}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
					<td mat-cell *matCellDef="let row">{{ row.description }}</td>
				</ng-container>

				<ng-container matColumnDef="class">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Class</th>
					<td mat-cell *matCellDef="let row">{{ row.class | titlecase }}</td>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<button (click)="delete($event, row.id)" mat-mini-fab color="warn" aria-label="Delete journal">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</div>
</div>
