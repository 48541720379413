import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TRANSACTION_FIELD } from '../constants';
import { TransactionField } from './store/transaction-field.model';

@Injectable({ providedIn: 'root' })
export class TransactionFieldService extends EntityCollectionServiceBase<TransactionField> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(TRANSACTION_FIELD, serviceElementsFactory);
	}
}
