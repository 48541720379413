<ng-container *ngIf="showLikeTextHint; else notTextTmpl">
  <div class="flex justify-between items-start">
    <i class="relative top-1px material-icons text-12px mt-3px">info_outline</i>
    <ng-container *ngIf="customTmpl; else txtTmpl">
      <div class="text-inp-hint! grow ml-8px text-inp-hint text-11px">
        <ng-container [ngTemplateOutlet]="customTmpl"></ng-container>
      </div>
    </ng-container>
    <ng-template #txtTmpl>
      <div
        class="text-inp-hint! grow ml-8px text-inp-hint text-11px leading-tight"
      >
        {{ text }}
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #notTextTmpl>
  <button
    type="button"
    tabindex="-1"
    matTooltip="{{ text }}"
    matTooltipClass="ib-tooltip"
    [matTooltipPosition]="position"
    (click)="isTouchScreen ? showModal() : null"
  >
    <ng-content></ng-content>
    <div
      *ngIf="!hideDefIcon"
      class="w-16px h-16px bg-icon-dark rounded-full flex justify-center items-center"
    >
      <i class="material-icons text-icon-white! text-10px text-semibold"
        >question_mark</i
      >
    </div>
  </button>

  <ng-container *ngIf="isTouchScreen">
    <ng-template #modalTmpl>
      <div class="p-5">
        <ng-container *ngIf="customTmpl; else txtModalTmpl">
          <ng-container [ngTemplateOutlet]="customTmpl"></ng-container>
        </ng-container>
        <ng-template #txtModalTmpl>
          <p>{{ text }}</p>
        </ng-template>
        <div class="flex justify-center mt-5">
          <button
            mat-button
            mat-dialog-close
            class="ib-btn ib-btn-outline ib-btn-secondary"
            cdkFocusInitial
          >
            Ok
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
