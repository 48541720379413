<div class="entity-container">
	<h1>Ledgers</h1>

	<div class="entity-actions">
		<button (click)="newEntity()" mat-raised-button [disabled]="!(loaded$ | async)">New Ledger</button>

		<button (click)="refresh()" mat-mini-fab color="primary" aria-label="Refresh data">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="" #input />
	</mat-form-field>

	<div class="table-container">
		<div class="loading-shade" *ngIf="loading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort>
				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let row">{{ row.name }}</td>
				</ng-container>

				<!-- Parent Column -->
				<ng-container matColumnDef="parentLedger">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Parent</th>
					<td mat-cell *matCellDef="let row">{{ row.parentLedger?.name }}</td>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<div class="actions" *ngIf="row.name !== MASTER_LEDGER">
							<button (click)="edit($event, row.id)" mat-mini-fab color="primary" aria-label="Edit ledger">
								<mat-icon>edit</mat-icon>
							</button>
							<button (click)="delete($event, row.id)" mat-mini-fab color="warn" aria-label="Delete ledger">
								<mat-icon>delete</mat-icon>
							</button>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row (click)="openLedger(row)" *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</div>
</div>
