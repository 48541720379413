<h2 mat-dialog-title>
	<span *ngIf="id; else create">Edit</span>
	<ng-template #create>New</ng-template>
	Model
</h2>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="transactionModelForm" class="flex-v">
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput id="name" name="name" required formControlName="name" />
			<mat-error *ngIf="name.hasError('required')">Name is required.</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Ref</mat-label>
			<input matInput id="code" name="code" required formControlName="ref" />
			<mat-error *ngIf="ref.hasError('required')">Ref is required.</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Product</mat-label>
			<mat-select formControlName="product">
				<mat-option *ngFor="let product of Object.values(Products)" [value]="product">{{ product }}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Types</mat-label>
			<mat-select formControlName="transactionTypes" multiple>
				<mat-option *ngFor="let itt of Object.values(InvestorTransactionType)" [value]="itt">{{ itt }}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Description</mat-label>
			<textarea matInput id="description" name="description" formControlName="description"></textarea>
		</mat-form-field>

		<div *ngIf="errors.length > 0" class="mat-mdc-form-field-error">
			<div *ngFor="let error of errors">{{ error }}</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-button (click)="saveTransactionModel()">Save</button>
</mat-dialog-actions>
