import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TRANSACTION_FIELD } from '../../constants';
import { TransactionField } from '../store/transaction-field.model';
import { TransactionFieldService } from '../transaction-field.service';
import { TransactionFieldDialogComponent } from '../transaction-field-dialog/transaction-field-dialog.component';
import { Products } from 'credebt-shared';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-transaction-fields',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './transaction-fields.component.html',
	styleUrls: ['./transaction-fields.component.scss'],
})
export class TransactionFieldsComponent extends BaseListComponent {
	loading$ = this.transactionFieldService.loading$;
	loaded$ = this.transactionFieldService.loaded$;
	transactionFields$ = this.transactionFieldService.entities$;
	transactionFields: TransactionField[];
	displayedColumns: string[] = [
		'index',
		'name',
		'products',
		'fieldType',
		'dataFormat',
		'defaultValue',
		'description',
		'formula',
		'actions',
	];
	filterSelectObj: any[] = [];
	filterValues = {};
	DIALOG_W = '960px';

	constructor(public transactionFieldService: TransactionFieldService, dialog: MatDialog, snackBar: MatSnackBar) {
		super(transactionFieldService, dialog, snackBar);
		this.dialogClass = TransactionFieldDialogComponent;
		this.transactionFields$.pipe(takeUntil(this.unsubscribe$)).subscribe(transactionFields => {
			this.transactionFields = transactionFields;
			this.dataSource = new MatTableDataSource(this.transactionFields);
			this.dataSource.sort = this.sort;
			this.dataSource.filterPredicate = this.createFilter();
			this.filterSelectObj.filter(o => {
				o.options = this.getFilterObject(this.transactionFields, o.columnProp);
			});
		});
		this.transactionFieldService.getAll();
	}

	ngOnInit() {
		this.prepareEventNotifications(TRANSACTION_FIELD, this.transactionFieldService.entityActions$);
		this.filterSelectObj = [
			{
				name: 'Products',
				columnProp: 'products',
				options: [],
			},
			{
				name: 'Field Type',
				columnProp: 'fieldType',
				options: [],
			},
			{
				name: 'Format',
				columnProp: 'dataFormat',
				options: [],
			},
		];
	}

	getFilterObject(tfs: TransactionField[], key: string) {
		const options: any[] = [];
		if (key === 'products') {
			return Object.values(Products);
		}
		tfs.filter(tf => {
			// @ts-ignore
			if (!options.includes(tf[key])) {
				// @ts-ignore
				options.push(tf[key]);
			}
			return tf;
		});
		return options;
	}

	filterChange(filter: { columnProp: string | number }, event: Event) {
		// console.log(filter);
		// @ts-ignore

		this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase();
		this.dataSource.filter = JSON.stringify(this.filterValues);
	}

	nameFilterChange(event: Event) {
		// @ts-ignore
		this.filterValues['name'] = event.target.value.trim().toLowerCase();
		this.dataSource.filter = JSON.stringify(this.filterValues);
	}

	resetFilters() {
		this.filterValues = {};
		this.filterSelectObj.forEach((value, key) => {
			value.modelValue = undefined;
		});
		this.dataSource.filter = '';
	}

	createFilter() {
		return function (data: any, filter: string): boolean {
			const searchTerms = JSON.parse(filter);
			let isFilterSet = false;
			for (const col in searchTerms) {
				if (searchTerms[col].toString() !== '') {
					isFilterSet = true;
				} else {
					delete searchTerms[col];
				}
			}

			const nameSearch = () => {
				const found: boolean[] = [];
				if (isFilterSet) {
					for (const col in searchTerms) {
						if (
							data[col].toString().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) != -1 &&
							isFilterSet
						) {
							found.push(true);
						}
					}
					return found.length === Object.values(searchTerms).length;
				} else {
					return true;
				}
			};
			return nameSearch();
		};
	}

	showProducts(products: string[]) {
		if (products?.length === Object.values(Products).length) return 'All';
		else return products.join(', ');
	}

	override edit($event: Event, id: string) {
		$event.stopPropagation();
		this.dialogRef = this.dialog.open(TransactionFieldDialogComponent, {
			width: this.DIALOG_W,
			data: {
				transactionFields: this.transactionFields,
				transactionField: this.transactionFields.find(tm => tm.id === id),
			},
		});
		return false;
	}

	override newEntity() {
		this.dialogRef = this.dialog.open(this.dialogClass, {
			width: this.DIALOG_W,
			data: {
				transactionFields: this.transactionFields,
			},
		});
	}
}
