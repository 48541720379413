import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Ledger } from '../store/ledger.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LedgerDialogComponent } from '../ledger-dialog/ledger-dialog.component';
import { LedgerService } from '../ledger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_MODAL_WIDTH, LEDGER, MASTER_LEDGER } from 'src/app/constants';
import { Router } from '@angular/router';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-ledgers',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './ledgers.component.html',
	styleUrls: ['./ledgers.component.scss'],
})
export class LedgersComponent extends BaseListComponent {
	loading$ = this.ledgerService.loading$;
	loaded$ = this.ledgerService.loaded$;
	ledgers$ = this.ledgerService.entities$;
	ledgers: Ledger[];
	displayedColumns: string[] = ['name', 'parentLedger', 'actions'];
	protected readonly MASTER_LEDGER = MASTER_LEDGER;

	constructor(public ledgerService: LedgerService, dialog: MatDialog, snackBar: MatSnackBar, private router: Router) {
		super(ledgerService, dialog, snackBar);
		this.ledgers$.pipe(takeUntil(this.unsubscribe$)).subscribe(ledgers => {
			this.ledgers = ledgers.map(ledger => {
				if (ledger.parentLedger && !ledger.parentLedger.id) {
					ledger = {
						...ledger,
						parentLedger: ledgers.find(l => l.id === (ledger.parentLedger as unknown as string)),
					};
				}
				return ledger;
			});
			this.dataSource = new MatTableDataSource(this.ledgers);
			this.dataSource.sort = this.sort;
		});
		this.ledgerService.getAll();
	}

	ngOnInit() {
		this.prepareEventNotifications(LEDGER, this.ledgerService.entityActions$);
	}

	override newEntity() {
		this.dialogRef = this.dialog.open(LedgerDialogComponent, {
			width: DEFAULT_MODAL_WIDTH,
			data: { ledgers: this.ledgers },
		});
	}

	override edit($event: Event, id: string) {
		$event.stopPropagation();
		this.dialogRef = this.dialog.open(LedgerDialogComponent, {
			width: DEFAULT_MODAL_WIDTH,
			data: { ledgers: this.ledgers, id },
		});
		return false;
	}

	openLedger(ledger: Ledger) {
		this.router.navigate(['/ledgers', ledger.id]);
	}
}
