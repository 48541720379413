<ng-content></ng-content>

<ng-container *ngIf="formCtrlName && formCtrl">
  <div
    *ngIf="formCtrl.invalid && formCtrl.touched && !hideErrors"
    class="overflow-hidden"
  >
    <app-input-error class="grow ml-8px text-11px mt-8px" [formCtrl]="formCtrl">
    </app-input-error>
  </div>

  <i
    *ngIf="showEditBtn && !showLoader"
    type="button"
    class="ib-mat-form-field-wrap-edit-btn material-icons cursor-pointer"
    (click)="editClicked?.emit($event)"
  >
    edit
  </i>

  <div
    *ngIf="isSelect"
    class="ib-mat-form-field-wrap-select-chevron flex justify-center items-center pointer-events-none"
  >
    <i class="material-icons {{ isSelectDropdownOpen ? 'rotate-180' : '' }}"
      >keyboard_arrow_down</i
    >
  </div>

  <ng-container *ngIf="!formCtrl.disabled && !showLoader">
    <app-input-clear-btn *ngIf="showClearBtn" [formCtrl]="formCtrl">
    </app-input-clear-btn>

    <button
      *ngIf="showPasswVisibilityBtn && htmlInputEl"
      mat-button
      type="button"
      class="ib-btn ib-btn-icon ib-mat-form-field-wrap-passw-toggle"
      (click)="togglePasswVisibility($event)"
    >
      <i class="material-icons cursor-pointer text-22px">
        {{ htmlInputEl.type === 'password' ? 'visibility' : 'visibility_off' }}
      </i>
    </button>
  </ng-container>

  <app-tooltip *ngIf="tooltipText" text="{{ tooltipText }}"> </app-tooltip>

  <app-spinner *ngIf="showLoader" [diameter]="20"></app-spinner>
</ng-container>
