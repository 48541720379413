<div>
	<button mat-button color="warn">Warning! This will delete existing data AND transactions!</button>

	<input class="p-1" [formControl]="fileForm" (change)="fileChange($event)" type="file" placeholder="File" />

	<div [hidden]="file === ''">
		<button class="my-4" (click)="uploadFile()" [disabled]="$importing | async" mat-raised-button color="primary">
			Upload
		</button>

		<div class="upload-progress">
			Upload progress = {{ $progress | async }} %
			<br />
			<mat-progress-bar color="primary" mode="determinate" [value]="$progress | async"></mat-progress-bar>
		</div>

		<div *ngIf="($importing | async) && ($progress | async) === 100">
			Importing...
			<mat-spinner></mat-spinner>
		</div>

		<div *ngIf="transactions && ($importing | async) === false && ($progress | async) === 100">
			Imported {{ transactions }} transactions.
		</div>
	</div>
</div>
