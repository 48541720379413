import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@cm-env/environment';
import { ISelectOption } from 'credebt-shared';

import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IntermediariesHttpService {
  constructor(private http: HttpClient) {}

  public fetchListForSelect(): Observable<Array<ISelectOption>> {
		return this.http
			.get<Array<{id: string; intermediary: string, intermediaryId: string}>>(`${environment.apiUrl}/api/getIntermediaries`)
			.pipe(
				map(r => {
					const map: Record<string, any> = {};
					(r || []).forEach(i => {
						map[i.intermediaryId] = i
					})
					const res = Object
						.values(map)
						.sort((a,b) => {
							if(a.intermediary < b.intermediary) { return -1; }
							if(a.intermediary > b.intermediary) { return 1; }
							return 0;
						})
						.map(i => ({label: i.intermediary, value: i.intermediaryId})) || []
					console.error(res);
					return res;
				})
			)
		}
}
