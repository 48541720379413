import { Component } from '@angular/core';
import { ApiService } from '../../../.api-client/services/api.service';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs';
import { BaseUnsubscibeComponent } from '../../shared/base-unsubscibe/base-unsubscibe.component';

@Component({
	selector: 'app-xero',
	templateUrl: './xero.component.html',
	styleUrls: ['./xero.component.scss'],
})
export class XeroComponent extends BaseUnsubscibeComponent {
	constructor(private api: ApiService) {
		super();
	}

	xeroLogin() {
		window.open(
			`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${environment.xeroClientId}&redirect_uri=${environment.xeroRedirectUri}&scope=openid profile email accounting.settings accounting.transactions accounting.contacts accounting.attachments&state=Xero`,
			'targetWindow',
			`toolbar=no,
							location=no,
							status=no,
							menubar=no,
							scrollbars=yes,
							resizable=yes,
							width=400,
							height=800`
		);
		if (window.addEventListener) {
			window.addEventListener('message', this.receiveMessage.bind(this), false);
		} else {
			(<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
		}
	}

	receiveMessage(evt: any) {
		if (evt.origin === window.origin && !!evt?.data?.code) {
			if (window.addEventListener) {
				window.removeEventListener('message', this.receiveMessage.bind(this), false);
			}
			this.api
				.xeroControllerPostXeroAuth({
					body: {
						code: evt.data.code,
						redirect_uri: environment.xeroRedirectUri,
					},
				})
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe(res => {
					console.log(res); //TODO - remove
				});
		}
	}

	getXeroAccounts() {
		this.api
			.xeroControllerGetAccounts()
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(res => {
				console.log(res);
			});
	}
}
