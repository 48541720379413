<div class="entity-container">
	<div>
		<mat-form-field class="investor-search">
			<mat-label>Select Investor:</mat-label>
			<input type="text" aria-label="Field" matInput [formControl]="investor" [matAutocomplete]="auto" />
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
				<mat-option [value]="ALL">All</mat-option>
				<mat-option *ngFor="let inv of filteredOptions | async" [value]="inv">
					{{ inv.name }}
					<span class="float-right">{{ inv.id }}</span>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<button mat-raised-button color="primary" class="float-right" (click)="csvExport()">CSV Export</button>
	</div>

	<div>
		<mat-form-field class="intermediary-search">
			<mat-label>Filter Intermediary ID</mat-label>
			<input matInput type="text" [formControl]="intermediary" />
			<button *ngIf="intermediaryVal" matSuffix mat-icon-button aria-label="Clear" (click)="intermediary.setValue('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>

	<div class="table-container">
		<table
			mat-table
			[dataSource]="data"
			class="table"
			matSort
			matSortActive="date"
			matSortDisableClear
			matSortDirection="desc">
			<ng-container matColumnDef="index">
				<th mat-header-cell *matHeaderCellDef>No.</th>
				<td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorId">
				<th mat-header-cell *matHeaderCellDef>Investor ID</th>
				<td mat-cell *matCellDef="let row">{{ row.investor?.id }}</td>
				<td mat-footer-cell *matFooterCellDef>Total</td>
			</ng-container>

			<ng-container matColumnDef="intermediaryID">
				<th mat-header-cell *matHeaderCellDef>Intermediary ID</th>
				<td mat-cell *matCellDef="let row">
					{{ row.intermediaryId }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="intermediary">
				<th mat-header-cell *matHeaderCellDef>Intermediary</th>
				<td mat-cell *matCellDef="let row" [title]="row.intermediary">
					{{ row.intermediary }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorName">
				<th mat-header-cell *matHeaderCellDef>Investor</th>
				<td mat-cell *matCellDef="let row" [title]="row.investor?.name">
					{{ row.investor?.name }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorFirstName">
				<th mat-header-cell *matHeaderCellDef>First Name</th>
				<td mat-cell *matCellDef="let row" [title]="row.investor?.firstName">
					{{ row.investor?.firstName }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorLastName">
				<th mat-header-cell *matHeaderCellDef>Last Name</th>
				<td mat-cell *matCellDef="let row" [title]="row.investor?.lastName">
					{{ row.investor?.lastName }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorEmail">
				<th mat-header-cell *matHeaderCellDef>Email</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.email }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorDateOfBrith">
				<th mat-header-cell *matHeaderCellDef>Born</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.dob | date : 'YYYY-MM-dd' }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorAddress1">
				<th mat-header-cell *matHeaderCellDef>Address 1</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.streetAndNumber }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorPhone">
				<th mat-header-cell *matHeaderCellDef>Phone</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.phone?.phone }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorMobilePhone">
				<th mat-header-cell *matHeaderCellDef>Mobile</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.mobilePhone?.phone }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investorAddress2">
				<th mat-header-cell *matHeaderCellDef>Address 2</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.city }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankName">
				<th mat-header-cell *matHeaderCellDef>Bank</th>
				<td mat-cell *matCellDef="let row" [title]="row.investor?.bankName">
					{{ row.investor?.bankName }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankSortCode">
				<th mat-header-cell *matHeaderCellDef>Code</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.bankSortCode }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankAccount">
				<th mat-header-cell *matHeaderCellDef>Account</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.bankAccount }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankSwiftBic">
				<th mat-header-cell *matHeaderCellDef>BIC</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.bankSwiftBic }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankIban">
				<th mat-header-cell *matHeaderCellDef>IBAN</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investor?.bankIban }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
				<td mat-cell *matCellDef="let row">
					{{ row.date | date : 'YYYY-MM-dd' }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="buyRate">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Rate</th>
				<td mat-cell *matCellDef="let row">{{ showPercent(row.buyRate) }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="transactionType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction</th>
				<td mat-cell *matCellDef="let row">
					{{ row.transactionType }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="ccy">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Ccy</th>
				<td mat-cell *matCellDef="let row">{{ row.ccy }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="faceValue">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Face Value</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					{{ row.faceValue | currency : 'EUR' : '' }}
				</td>
				<td mat-footer-cell *matFooterCellDef>
					{{ totals('faceValue') | currency : 'EUR' : '' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="commission">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Comm.</th>
				<td mat-cell *matCellDef="let row" class="text-center">{{ showPercent(row.commission) }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="commissionResult">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Result</th>
				<td mat-cell *matCellDef="let row" class="text-center">
					{{ row.partial?.commissionResult | currency : 'EUR' : '' }}
				</td>
				<td mat-footer-cell *matFooterCellDef>
					{{ totals('partial.commissionResult') | currency : 'EUR' : '' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="months">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Months</th>
				<td mat-cell *matCellDef="let row" class="text-center">
					{{ row.months }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="investmentType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
				<td mat-cell *matCellDef="let row">
					{{ row.investmentType }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="yield">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Yield</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					<span *ngIf="isNumber(row.partial?.yield)">
						{{ row.partial?.yield | currency : 'EUR' : '' }}
					</span>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					{{ totals('partial.yield') | currency : 'EUR' : '' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="balance">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					<span *ngIf="isNumber(row.partial?.balance)">
						{{ row.partial?.balance | currency : 'EUR' : '' }}
					</span>
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="eurCommission">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>EUR Comm.</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					{{ row.eurCommission | currency : 'EUR' : '' }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="bankExchange">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Exch.</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					{{ row.partial?.bankExchange | currency : 'EUR' : '' }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="eurBankExchange">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>EUR Exch.</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					{{ row.partial?.eurBankExchange | currency : 'EUR' : '' }}
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<ng-container matColumnDef="marginCall">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Margin Call</th>
				<td mat-cell *matCellDef="let row" class="text-right">
					<span *ngIf="isNumber(row.partial?.marginCall)">
						{{ row.partial?.marginCall | currency : 'EUR' : '' }}
					</span>
				</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
		</table>
	</div>

	<mat-paginator
		[length]="resultsLength"
		[pageSize]="pageSize"
		aria-label="Select page of search results"></mat-paginator>
</div>
