import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LedgerService } from '../ledger.service';
import { map } from 'rxjs/operators';
import { Ledger } from '../store/ledger.model';
import { Observable, takeUntil } from 'rxjs';
import { JournalService } from '../journal.service';
import { Journal } from '../store/journal.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BaseUnsubscibeComponent } from '../../shared/base-unsubscibe/base-unsubscibe.component';

@Component({
	selector: 'app-ledger-details',
	templateUrl: './ledger-details.component.html',
	styleUrls: ['./ledger-details.component.scss'],
})
export class LedgerDetailsComponent extends BaseUnsubscibeComponent implements OnInit, AfterViewInit {
	ledger$: Observable<Ledger>;
	loading$ = this.journalService.loading$;
	loaded$ = this.journalService.loaded$;
	journals$ = this.journalService.entities$;
	journals: Journal[];
	displayedColumns: string[] = ['check', 'name', 'code', 'status', 'type', 'taxType', 'description', 'class'];
	dataSource!: MatTableDataSource<Journal>;
	@ViewChild(MatSort) sort!: MatSort;

	constructor(
		public ledgerService: LedgerService,
		public journalService: JournalService,
		private route: ActivatedRoute
	) {
		super();
		this.journals$.pipe(takeUntil(this.unsubscribe$)).subscribe(journals => {
			this.journals = journals;
			this.dataSource = new MatTableDataSource(this.journals);
			this.dataSource.sort = this.sort;
		});
		this.journalService.getAll();
	}

	ngOnInit() {
		this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: Params) => {
			this.ledger$ = this.ledgerService.entities$.pipe(map(ledgers => ledgers.find(l => l.id === params['id'])));
			this.ledgerService.getByKey(params['id']);
		});
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
