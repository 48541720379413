import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';

const ANIMATION_DEFAULT_TIME = 300

export const AnimateInOpacityFromTop = function (
  timeMs?: string,
): AnimationTriggerMetadata {
  return trigger('inOpacityFromTop', [
    transition(':enter', [
      style({
        opacity: '0',
        transform: 'translateY(-100%)',
      }),
      animate(
        timeMs || ANIMATION_DEFAULT_TIME,
        style({
          opacity: '1',
          transform: 'translateY(0)',
        }),
      ),
    ]),
  ]);
};
