import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-clear-btn',
  templateUrl: './input-clear-btn.component.html',
})
export class InputClearBtnComponent {
  @Input() formCtrl!: AbstractControl;
  @Input() valueAfterClear?: null | [] | undefined | '' = undefined;

  clear(): void {
    this.formCtrl.setValue(undefined);
    this.formCtrl.markAsDirty();
    this.formCtrl.markAsTouched();
  }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [InputClearBtnComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [InputClearBtnComponent],
})
export class InputClearBtnModule {}
