import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class DateTimeFormatService {
  private readonly apiFormatToBackend = 'YYYY-MM-DD';
  private readonly apiFormatFromBackend = 'YYYY-MM-DD';
  public datesView$ = new BehaviorSubject<string>('YYYY-MM-DD');
  public timeView$ = new BehaviorSubject<string>('h:mm a');
  public timezone$ = new BehaviorSubject<string>('todo');

  // todo
  // get user country and define preferable date/time formats - https://tableconvert.com/json-to-json

  get tz(): string {
    return this.timezone$.value;
  }

  get dateFormatForUi(): string {
    return this.datesView$.value || 'YYYY-MM-DD';
  }

  public setTimezone(v: string): void {
    this.timezone$.next(v);
  }

  public getDateFormatForApi(): string {
    return this.apiFormatToBackend;
  }

  public transformUiDateFormatToApiFormat(date: string | Date): string {
    return dayjs(typeof date === 'string' ? new Date(date) : date).format(this.apiFormatToBackend);
  }

  public transformApiFormatToDatePickerFormat(date: string): Date {
    return dayjs(date, this.apiFormatFromBackend).toDate();
  }

  public transformDateToApiFormat(date: Date): string {
    return dayjs(date).format(this.apiFormatToBackend);
  }

  public getCurrDateByIsoForApi(): string {
    return new Date().toISOString().slice(0, 10);
  }

  public selectUiDateFormat(): Observable<string> {
    return this.datesView$.asObservable();
  }
  public setUiDateFormat(format: string): void {
    this.datesView$.next(format);
  }
  get uiDateFormat(): string {
    return this.datesView$.value;
  }

  public getTodayForUi(): string {
    return dayjs(new Date()).format(this.uiDateFormat);
  }
}
