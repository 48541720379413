import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TRANSACTION_MODEL } from '../constants';
import { TransactionModelModel } from './store/transaction-model.model';

@Injectable({ providedIn: 'root' })
export class TransactionModelService extends EntityCollectionServiceBase<TransactionModelModel> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(TRANSACTION_MODEL, serviceElementsFactory);
	}
}
