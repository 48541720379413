import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  @Input() fluid: boolean;
  @Input() diameter?: number;
  @Input() isInsideBtn?: boolean;

  @HostBinding('class') get classes(): string {
    return [
      this.fluid ? 'ib-spinner--fluid' : '',
      this.isInsideBtn ? 'ib-spinner--inside-btn' : '',
    ].join(' ');
  }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [SpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [SpinnerComponent],
})
export class CmSpinnerModule {}
