<h2 mat-dialog-title>
	<span *ngIf="id; else create">Edit</span>
	<ng-template #create>New</ng-template>
	Ledger
</h2>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="ledgerForm" class="flex-v">
		<mat-form-field>
			<mat-label>Ledger Name</mat-label>
			<input matInput id="name" name="name" required formControlName="name" />
			<mat-error *ngIf="name.hasError('required')">Name is required.</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Parent</mat-label>
			<mat-select formControlName="parent" required>
				<mat-option *ngFor="let ledger of data.ledgers" [value]="ledger.id">
					{{ ledger.name }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="parent.hasError('required')">Parent ledger is required.</mat-error>
		</mat-form-field>

		<div *ngIf="errors.length > 0" class="mat-mdc-form-field-error">
			<div *ngFor="let error of errors">{{ error }}</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-button (click)="saveLedger()">Save</button>
</mat-dialog-actions>
