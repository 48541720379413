<div class="entity-container">
	<h1>{{ (transactionModel$ | async)?.name }}</h1>
	<h3>
		<span class="font-medium">Ref:</span>
		{{ (transactionModel$ | async)?.ref }}
	</h3>
	<h3>
		<span class="font-medium">Product:</span>
		{{ (transactionModel$ | async)?.product }}
	</h3>
	<h3>
		<span class="font-medium">Description:</span>
		{{ (transactionModel$ | async)?.description }}
	</h3>

	<h2>Journal Lines:</h2>

	<div>
		<mat-radio-group aria-label="Select an option" [(ngModel)]="mode" (ngModelChange)="onModeChange($event)">
			<mat-radio-button *ngFor="let m of Object.values(Modes)" [value]="m">
				{{ m }}
			</mat-radio-button>
		</mat-radio-group>

		<button
			[hidden]="mode === Modes.TEST"
			id="newLine"
			(click)="newJournalLine()"
			mat-raised-button
			[disabled]="!transactionModel">
			New Journal Line
		</button>
	</div>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="" #input />
	</mat-form-field>

	<div class="table-container">
		<div class="loading-shade" *ngIf="jlLoading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="order" matSortDirection="asc">
				<!-- Name Column -->
				<ng-container matColumnDef="order">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
					<td mat-cell *matCellDef="let row">{{ row.order + 1 }}</td>
					<td mat-footer-cell *matFooterCellDef>Total</td>
				</ng-container>

				<ng-container matColumnDef="journal">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Journal</th>
					<td mat-cell *matCellDef="let row">{{ row.journal?.name }}</td>
					<td mat-footer-cell *matFooterCellDef>
						{{ total | currency : 'EUR' : '' : '1.0-0' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="code">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
					<td mat-cell *matCellDef="let row">{{ row.journal?.code }}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="transactionField">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Field</th>
					<td mat-cell *matCellDef="let row">
						{{ row.transactionField?.name }}
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="transactionFieldType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
					<td mat-cell *matCellDef="let row">
						{{ row.transactionField?.fieldType | titlecase }}
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="transactionFieldDataFormat">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
					<td mat-cell *matCellDef="let row">
						{{ row.transactionField?.dataFormat | titlecase }}
					</td>
					<td mat-footer-cell *matFooterCellDef>
						{{ lastDate | date : 'YYYY-MM-dd' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Use as</th>
					<td mat-cell *matCellDef="let row">{{ row.type }}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<button (click)="edit($event, row.id)" mat-mini-fab color="primary" aria-label="Edit journal line">
							<mat-icon>edit</mat-icon>
						</button>
						<button (click)="delete($event, row.id)" mat-mini-fab color="warn" aria-label="Delete journal line">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="debit">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="center">Debit</th>
					<td mat-cell *matCellDef="let row" class="text-right">
						<span *ngIf="isNumber(row.debit)">
							{{ row.debit | currency : 'EUR' : '' }}
						</span>
					</td>
					<td mat-footer-cell *matFooterCellDef class="text-right">
						{{ totalDebit | currency : 'EUR' : '' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="credit">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="center">Credit</th>
					<td mat-cell *matCellDef="let row" class="text-right">
						<span *ngIf="isNumber(row.credit)">
							{{ row.credit | currency : 'EUR' : '' }}
						</span>
					</td>
					<td mat-footer-cell *matFooterCellDef class="text-right">
						{{ totalCredit | currency : 'EUR' : '' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="posting">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="center">Posting</th>
					<td mat-cell *matCellDef="let row" class="text-right">
						<span *ngIf="isNumber(row.posting)">
							{{ row.posting | currency : 'EUR' : '' }}
						</span>
					</td>
					<td mat-footer-cell *matFooterCellDef class="text-right">
						{{ totalPosting | currency : 'EUR' : '' }}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumns" [hidden]="mode === Modes.EDIT"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</div>

	<div [hidden]="mode === Modes.EDIT" class="py-8">
		<div class="pb-8">Last Transaction Date:</div>

		<div class="flex space-x-4">
			<button mat-raised-button (click)="updateResult()">Update Result</button>
			<button mat-raised-button (click)="verifyAll()" [disabled]="verifying$ | async">Verify All Transactions</button>
		</div>

		<h3 class="pt-8">Required input fields:</h3>

		<div [formGroup]="testDataForm">
			<div id="testFieldList" class="flex-v" *ngFor="let field of (transactionModel$ | async)?.fieldsForTesting">
				<label [for]="field.id">{{ field.name }}</label>
				<input type="text" [id]="field.id" [formControlName]="field.id" />
			</div>
		</div>

		<div>Errors: {{ errors }}</div>
		<div>OK: {{ ok }}</div>
		<div>No Change: {{ noChange }}</div>
	</div>
</div>
