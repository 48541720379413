import { Component, Inject } from '@angular/core';
import { BaseDialogComponent } from '../../shared/base-dialog/base-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { Journal } from '../../chart-of-accounts/store/journal.model';
import { JournalLine, JournalLineType } from '../store/journal-line.model';
import { JournalLineService } from '../journal-line.service';
import { TransactionField } from '../store/transaction-field.model';
import { FieldType } from 'credebt-shared';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-journal-line-dialog',
	templateUrl: './journal-line-dialog.component.html',
	styleUrls: ['./journal-line-dialog.component.scss'],
})
export class JournalLineDialogComponent extends BaseDialogComponent {
	journalLineForm = new FormGroup({
		journalId: new FormControl('', Validators.required),
		transactionModelId: new FormControl(''),
		transactionField: new FormControl<TransactionField>(null),
		type: new FormControl<JournalLineType>(JournalLineType.DEBIT, Validators.required),
	});
	journalLineTypes = Object.values(JournalLineType);
	filteredOptions: Observable<TransactionField[]>;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			journalLines: JournalLine[];
			journals: Journal[];
			transactionFields: TransactionField[];
			transactionModelId: string;
			id: string | null;
		},
		public jlService: JournalLineService
	) {
		super();
		this.errors$ = this.jlService.errors$.pipe(map(error => error.payload.data.error.error));

		this.id = data?.id;
		if (this.id) {
			const journalLine = data.journalLines.find(jl => jl.id === data.id);
			this.journalLineForm.patchValue({
				journalId: journalLine.journal.id,
				transactionModelId: data.transactionModelId,
				transactionField: journalLine.transactionField,
				type: journalLine.type,
			});
		} else {
			this.journalLineForm.controls.transactionModelId.setValue(data.transactionModelId);
		}
		this.filteredOptions = this.transactionField.valueChanges.pipe(
			startWith(''),
			map(value => {
				const filterValue = typeof value === 'string' ? value : value?.name;
				return this.data.transactionFields.filter(tf => tf.name.toLowerCase().includes(filterValue.toLowerCase()));
			})
		);
	}

	get journalId() {
		return this.journalLineForm.get('journalId');
	}

	get type() {
		return this.journalLineForm.get('type');
	}

	get transactionField() {
		return this.journalLineForm.get('transactionField');
	}

	saveJournalLine() {
		this.errors = [];
		this.journalLineForm.markAllAsTouched();
		if (this.journalLineForm.valid) {
			if (this.id) {
				this.jlService.update({
					...this.journalLineForm.getRawValue(),
					id: this.id,
					transactionFieldId: this.transactionField.value.id,
				});
			} else {
				this.jlService.add({
					...this.journalLineForm.getRawValue(),
					transactionFieldId: this.transactionField.value.id,
				});
			}
		}
	}

	displayFn(tf: TransactionField): string {
		return tf && tf.name ? tf.name : '';
	}

	protected readonly FieldType = FieldType;
}
