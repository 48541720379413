/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckIsPhoneAvailableDto } from '../models/check-is-phone-available-dto';
import { CheckPhoneSecureCodeDto } from '../models/check-phone-secure-code-dto';
import { CreateInvestorDto } from '../models/create-investor-dto';
import { CreateJournalLineDto } from '../models/create-journal-line-dto';
import { CreateLedgerDto } from '../models/create-ledger-dto';
import { CreatePhoneDto } from '../models/create-phone-dto';
import { CreateTransactionFieldDto } from '../models/create-transaction-field-dto';
import { CreateTransactionModelDto } from '../models/create-transaction-model-dto';
import { CreateUserDto } from '../models/create-user-dto';
import { ForgotPasswordDto } from '../models/forgot-password-dto';
import { InvestorDataCreateDto } from '../models/investor-data-create-dto';
import { InvestorLoginDto } from '../models/investor-login-dto';
import { InvestorRegistrDocumentDto } from '../models/investor-registr-document-dto';
import { ResetPasswordDto } from '../models/reset-password-dto';
import { UpdateJournalLineDto } from '../models/update-journal-line-dto';
import { UpdateLedgerDto } from '../models/update-ledger-dto';
import { UpdateTransactionFieldDto } from '../models/update-transaction-field-dto';
import { UpdateTransactionModelDto } from '../models/update-transaction-model-dto';
import { UpdateUserDto } from '../models/update-user-dto';
import { XeroAuthDto } from '../models/xero-auth-dto';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authControllerUploadFile
   */
  static readonly AuthControllerUploadFilePath = '/api/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerUploadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerUploadFile$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerUploadFilePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerUploadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerUploadFile(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerUploadFile$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerLogin
   */
  static readonly AuthControllerLoginPath = '/api/auth/loginInvestor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin$Response(params: {
    body: InvestorLoginDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin(params: {
    body: InvestorLoginDto
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerLogin$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerForgotPassword
   */
  static readonly AuthControllerForgotPasswordPath = '/api/auth/forgotPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerForgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerForgotPassword$Response(params: {
    body: ForgotPasswordDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerForgotPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerForgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerForgotPassword(params: {
    body: ForgotPasswordDto
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerForgotPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerResetPassword
   */
  static readonly AuthControllerResetPasswordPath = '/api/auth/resetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerResetPassword$Response(params: {
    body: ResetPasswordDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerResetPassword(params: {
    body: ResetPasswordDto
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerResetPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerCheckResetPasswordCode
   */
  static readonly AuthControllerCheckResetPasswordCodePath = '/api/auth/checkResetPasswordCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerCheckResetPasswordCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCheckResetPasswordCode$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerCheckResetPasswordCodePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerCheckResetPasswordCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCheckResetPasswordCode(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerCheckResetPasswordCode$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerMe
   */
  static readonly AuthControllerMePath = '/api/auth/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerMe$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerMePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerMe(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerMe$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerLogout
   */
  static readonly AuthControllerLogoutPath = '/api/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerLogoutPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerLogout$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerSaveInvestor
   */
  static readonly AuthControllerSaveInvestorPath = '/api/auth/save-investor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSaveInvestor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSaveInvestor$Response(params: {
    body: CreateInvestorDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AuthControllerSaveInvestorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerSaveInvestor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSaveInvestor(params: {
    body: CreateInvestorDto
  },
  context?: HttpContext

): Observable<void> {

    return this.authControllerSaveInvestor$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerFindAll
   */
  static readonly UsersControllerFindAllPath = '/api/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerCreate
   */
  static readonly UsersControllerCreatePath = '/api/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerCreate$Response(params: {
    body: CreateUserDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerCreate(params: {
    body: CreateUserDto
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerMyself
   */
  static readonly UsersControllerMyselfPath = '/api/users/myself';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerMyself()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerMyself$Response(params: {
    body: CreateUserDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerMyselfPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerMyself$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerMyself(params: {
    body: CreateUserDto
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerMyself$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerFindOne
   */
  static readonly UsersControllerFindOnePath = '/api/users/{oid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOne$Response(params: {
    oid: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerFindOnePath, 'get');
    if (params) {
      rb.path('oid', params.oid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOne(params: {
    oid: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerRemove
   */
  static readonly UsersControllerRemovePath = '/api/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersControllerUpdate
   */
  static readonly UsersControllerUpdatePath = '/api/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdate$Response(params: {
    id: string;
    body: UpdateUserDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UsersControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdate(params: {
    id: string;
    body: UpdateUserDto
  },
  context?: HttpContext

): Observable<void> {

    return this.usersControllerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation xeroControllerGetAccounts
   */
  static readonly XeroControllerGetAccountsPath = '/api/xero';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `xeroControllerGetAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  xeroControllerGetAccounts$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.XeroControllerGetAccountsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `xeroControllerGetAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  xeroControllerGetAccounts(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.xeroControllerGetAccounts$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation xeroControllerPostXeroAuth
   */
  static readonly XeroControllerPostXeroAuthPath = '/api/xero';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `xeroControllerPostXeroAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  xeroControllerPostXeroAuth$Response(params: {
    body: XeroAuthDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.XeroControllerPostXeroAuthPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `xeroControllerPostXeroAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  xeroControllerPostXeroAuth(params: {
    body: XeroAuthDto
  },
  context?: HttpContext

): Observable<void> {

    return this.xeroControllerPostXeroAuth$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation chartOfAccountsControllerFindAll
   */
  static readonly ChartOfAccountsControllerFindAllPath = '/api/journals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chartOfAccountsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  chartOfAccountsControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ChartOfAccountsControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chartOfAccountsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chartOfAccountsControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.chartOfAccountsControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation chartOfAccountsControllerFindOne
   */
  static readonly ChartOfAccountsControllerFindOnePath = '/api/journal/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chartOfAccountsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  chartOfAccountsControllerFindOne$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ChartOfAccountsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chartOfAccountsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chartOfAccountsControllerFindOne(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.chartOfAccountsControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ledgerControllerCreate
   */
  static readonly LedgerControllerCreatePath = '/api/ledger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ledgerControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ledgerControllerCreate$Response(params: {
    body: CreateLedgerDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LedgerControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ledgerControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ledgerControllerCreate(params: {
    body: CreateLedgerDto
  },
  context?: HttpContext

): Observable<void> {

    return this.ledgerControllerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ledgerControllerFindAll
   */
  static readonly LedgerControllerFindAllPath = '/api/ledgers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ledgerControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LedgerControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ledgerControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.ledgerControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ledgerControllerFindOne
   */
  static readonly LedgerControllerFindOnePath = '/api/ledger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ledgerControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerFindOne$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LedgerControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ledgerControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerFindOne(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.ledgerControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ledgerControllerUpdate
   */
  static readonly LedgerControllerUpdatePath = '/api/ledger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ledgerControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ledgerControllerUpdate$Response(params: {
    id: string;
    body: UpdateLedgerDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LedgerControllerUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ledgerControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ledgerControllerUpdate(params: {
    id: string;
    body: UpdateLedgerDto
  },
  context?: HttpContext

): Observable<void> {

    return this.ledgerControllerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ledgerControllerRemove
   */
  static readonly LedgerControllerRemovePath = '/api/ledger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ledgerControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LedgerControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ledgerControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ledgerControllerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.ledgerControllerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerCreate
   */
  static readonly TransactionModelControllerCreatePath = '/api/transactionmodel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerCreate$Response(params: {
    body: CreateTransactionModelDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerCreate(params: {
    body: CreateTransactionModelDto
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerTest
   */
  static readonly TransactionModelControllerTestPath = '/api/transactionmodel/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerTest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerTest$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerTestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerTest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerTest(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerTest$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerFindAll
   */
  static readonly TransactionModelControllerFindAllPath = '/api/transactionmodels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerFindOne
   */
  static readonly TransactionModelControllerFindOnePath = '/api/transactionmodel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerFindOne$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerFindOne(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerUpdate
   */
  static readonly TransactionModelControllerUpdatePath = '/api/transactionmodel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerUpdate$Response(params: {
    id: string;
    body: UpdateTransactionModelDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionModelControllerUpdate(params: {
    id: string;
    body: UpdateTransactionModelDto
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionModelControllerRemove
   */
  static readonly TransactionModelControllerRemovePath = '/api/transactionmodel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionModelControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionModelControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionModelControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionModelControllerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionModelControllerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation journalLineControllerCreate
   */
  static readonly JournalLineControllerCreatePath = '/api/journalline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `journalLineControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  journalLineControllerCreate$Response(params: {
    body: CreateJournalLineDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JournalLineControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `journalLineControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  journalLineControllerCreate(params: {
    body: CreateJournalLineDto
  },
  context?: HttpContext

): Observable<void> {

    return this.journalLineControllerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation journalLineControllerFindAll
   */
  static readonly JournalLineControllerFindAllPath = '/api/journallines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `journalLineControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JournalLineControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `journalLineControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.journalLineControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation journalLineControllerFindOne
   */
  static readonly JournalLineControllerFindOnePath = '/api/journalline/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `journalLineControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerFindOne$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JournalLineControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `journalLineControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerFindOne(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.journalLineControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation journalLineControllerUpdate
   */
  static readonly JournalLineControllerUpdatePath = '/api/journalline/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `journalLineControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  journalLineControllerUpdate$Response(params: {
    id: string;
    body: UpdateJournalLineDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JournalLineControllerUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `journalLineControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  journalLineControllerUpdate(params: {
    id: string;
    body: UpdateJournalLineDto
  },
  context?: HttpContext

): Observable<void> {

    return this.journalLineControllerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation journalLineControllerRemove
   */
  static readonly JournalLineControllerRemovePath = '/api/journalline/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `journalLineControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JournalLineControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `journalLineControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  journalLineControllerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.journalLineControllerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionFieldControllerCreate
   */
  static readonly TransactionFieldControllerCreatePath = '/api/transactionfield';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionFieldControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionFieldControllerCreate$Response(params: {
    body: CreateTransactionFieldDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionFieldControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionFieldControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionFieldControllerCreate(params: {
    body: CreateTransactionFieldDto
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionFieldControllerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionFieldControllerFindAll
   */
  static readonly TransactionFieldControllerFindAllPath = '/api/transactionfields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionFieldControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionFieldControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionFieldControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionFieldControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionFieldControllerFindOne
   */
  static readonly TransactionFieldControllerFindOnePath = '/api/transactionfield/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionFieldControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerFindOne$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionFieldControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionFieldControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerFindOne(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionFieldControllerFindOne$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionFieldControllerUpdate
   */
  static readonly TransactionFieldControllerUpdatePath = '/api/transactionfield/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionFieldControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionFieldControllerUpdate$Response(params: {
    id: string;
    body: UpdateTransactionFieldDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionFieldControllerUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionFieldControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionFieldControllerUpdate(params: {
    id: string;
    body: UpdateTransactionFieldDto
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionFieldControllerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionFieldControllerRemove
   */
  static readonly TransactionFieldControllerRemovePath = '/api/transactionfield/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionFieldControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TransactionFieldControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionFieldControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionFieldControllerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.transactionFieldControllerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerUploadFile
   */
  static readonly ImporterControllerUploadFilePath = '/api/importer/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerUploadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerUploadFile$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerUploadFilePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerUploadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerUploadFile(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerUploadFile$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerFindAll
   */
  static readonly ImporterControllerFindAllPath = '/api/investordatas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAll$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAll(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerFindAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerVerifyAll
   */
  static readonly ImporterControllerVerifyAllPath = '/api/investordata/{id}/verifyAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerVerifyAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerVerifyAll$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerVerifyAllPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerVerifyAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerVerifyAll(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerVerifyAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerFindAllInvestorTransactions
   */
  static readonly ImporterControllerFindAllInvestorTransactionsPath = '/api/investortransactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerFindAllInvestorTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactions$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerFindAllInvestorTransactionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerFindAllInvestorTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactions(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerFindAllInvestorTransactions$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerFindAllInvestorTransactionsForStatement
   */
  static readonly ImporterControllerFindAllInvestorTransactionsForStatementPath = '/api/tte/investortransactions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerFindAllInvestorTransactionsForStatement()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactionsForStatement$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerFindAllInvestorTransactionsForStatementPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerFindAllInvestorTransactionsForStatement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactionsForStatement(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerFindAllInvestorTransactionsForStatement$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerFindAllInvestorTransactionsForTte
   */
  static readonly ImporterControllerFindAllInvestorTransactionsForTtePath = '/api/fullinvestortransactions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerFindAllInvestorTransactionsForTte()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactionsForTte$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerFindAllInvestorTransactionsForTtePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerFindAllInvestorTransactionsForTte$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorTransactionsForTte(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerFindAllInvestorTransactionsForTte$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerFindAllInvestorIds
   */
  static readonly ImporterControllerFindAllInvestorIdsPath = '/api/investorids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerFindAllInvestorIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorIds$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerFindAllInvestorIdsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerFindAllInvestorIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerFindAllInvestorIds(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerFindAllInvestorIds$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerGetInvestorBalance
   */
  static readonly ImporterControllerGetInvestorBalancePath = '/api/investorbalance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerGetInvestorBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerGetInvestorBalance$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerGetInvestorBalancePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerGetInvestorBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerGetInvestorBalance(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerGetInvestorBalance$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerGetIntermediaries
   */
  static readonly ImporterControllerGetIntermediariesPath = '/api/getIntermediaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerGetIntermediaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerGetIntermediaries$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerGetIntermediariesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerGetIntermediaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerGetIntermediaries(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerGetIntermediaries$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerCreateInvestorData
   */
  static readonly ImporterControllerCreateInvestorDataPath = '/api/investordata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerCreateInvestorData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importerControllerCreateInvestorData$Response(params: {
    body: InvestorDataCreateDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerCreateInvestorDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerCreateInvestorData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importerControllerCreateInvestorData(params: {
    body: InvestorDataCreateDto
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerCreateInvestorData$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importerControllerDelete
   */
  static readonly ImporterControllerDeletePath = '/api/investordata/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importerControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerDelete$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ImporterControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importerControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importerControllerDelete(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.importerControllerDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerCheckEmail
   */
  static readonly InvestorControllerCheckEmailPath = '/api/investor/checkIsEmailAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerCheckEmail$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerCheckEmailPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerCheckEmail(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerCheckEmail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerConfirmRegistration
   */
  static readonly InvestorControllerConfirmRegistrationPath = '/api/investor/confirmRegistration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerConfirmRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerConfirmRegistration$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerConfirmRegistrationPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerConfirmRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerConfirmRegistration(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerConfirmRegistration$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerGetFullListForSelect
   */
  static readonly InvestorControllerGetFullListForSelectPath = '/api/investor/listForSelect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerGetFullListForSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerGetFullListForSelect$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerGetFullListForSelectPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerGetFullListForSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerGetFullListForSelect(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerGetFullListForSelect$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerVerifyAll
   */
  static readonly InvestorControllerVerifyAllPath = '/api/investor/getById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerVerifyAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerVerifyAll$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerVerifyAllPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerVerifyAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerVerifyAll(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerVerifyAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerRegistrationStepMarketing
   */
  static readonly InvestorControllerRegistrationStepMarketingPath = '/api/investor/registrMarketing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerRegistrationStepMarketing()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerRegistrationStepMarketing$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerRegistrationStepMarketingPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerRegistrationStepMarketing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerRegistrationStepMarketing(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerRegistrationStepMarketing$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerRegistrationDocument
   */
  static readonly InvestorControllerRegistrationDocumentPath = '/api/investor/saveRegistrationDocument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerRegistrationDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  investorControllerRegistrationDocument$Response(params: {
    body: InvestorRegistrDocumentDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerRegistrationDocumentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerRegistrationDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  investorControllerRegistrationDocument(params: {
    body: InvestorRegistrDocumentDto
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerRegistrationDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerIncreaseBalance
   */
  static readonly InvestorControllerIncreaseBalancePath = '/api/investor/increase-balance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerIncreaseBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerIncreaseBalance$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerIncreaseBalancePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerIncreaseBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerIncreaseBalance(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerIncreaseBalance$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerSendRegistrLink
   */
  static readonly InvestorControllerSendRegistrLinkPath = '/api/investor/sendRegistrLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerSendRegistrLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerSendRegistrLink$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerSendRegistrLinkPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerSendRegistrLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerSendRegistrLink(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerSendRegistrLink$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation investorControllerSendRegistrCodeToPhone
   */
  static readonly InvestorControllerSendRegistrCodeToPhonePath = '/api/investor/sendRegistrCodeToPhone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `investorControllerSendRegistrCodeToPhone()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerSendRegistrCodeToPhone$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InvestorControllerSendRegistrCodeToPhonePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `investorControllerSendRegistrCodeToPhone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  investorControllerSendRegistrCodeToPhone(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.investorControllerSendRegistrCodeToPhone$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation phoneControllerSendSecureCode
   */
  static readonly PhoneControllerSendSecureCodePath = '/api/phone/sendSecureCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneControllerSendSecureCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerSendSecureCode$Response(params: {
    body: CreatePhoneDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PhoneControllerSendSecureCodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneControllerSendSecureCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerSendSecureCode(params: {
    body: CreatePhoneDto
  },
  context?: HttpContext

): Observable<void> {

    return this.phoneControllerSendSecureCode$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation phoneControllerCheckIsExist
   */
  static readonly PhoneControllerCheckIsExistPath = '/api/phone/checkIsPhoneAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneControllerCheckIsExist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerCheckIsExist$Response(params: {
    body: CheckIsPhoneAvailableDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PhoneControllerCheckIsExistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneControllerCheckIsExist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerCheckIsExist(params: {
    body: CheckIsPhoneAvailableDto
  },
  context?: HttpContext

): Observable<void> {

    return this.phoneControllerCheckIsExist$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation phoneControllerCheckSecureCode
   */
  static readonly PhoneControllerCheckSecureCodePath = '/api/phone/checkSecureCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneControllerCheckSecureCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerCheckSecureCode$Response(params: {
    body: CheckPhoneSecureCodeDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PhoneControllerCheckSecureCodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneControllerCheckSecureCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneControllerCheckSecureCode(params: {
    body: CheckPhoneSecureCodeDto
  },
  context?: HttpContext

): Observable<void> {

    return this.phoneControllerCheckSecureCode$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
