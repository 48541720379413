import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { BaseDialogComponent } from '../../shared/base-dialog/base-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { TransactionField } from '../store/transaction-field.model';
import { TransactionFieldService } from '../transaction-field.service';
import { Format, FieldType } from 'credebt-shared';
import { EditorState } from '@codemirror/state';
import {
	Decoration,
	DecorationSet,
	EditorView,
	MatchDecorator,
	ViewPlugin,
	ViewUpdate,
	WidgetType,
} from '@codemirror/view';
import { basicSetup } from 'codemirror';
import { spreadsheet } from 'codemirror-lang-spreadsheet';
import { Products } from 'credebt-shared';

// Used for Field in Formula Editor
class PlaceholderWidget extends WidgetType {
	constructor(readonly name: string) {
		super();
	}

	override eq(other: PlaceholderWidget) {
		return this.name == other.name;
	}

	toDOM() {
		let elt = document.createElement('span');
		elt.style.cssText = `
      border: 1px solid blue;
      border-radius: 4px;
      padding: 0 3px;
      margin-right: 2px;
      background: lightblue;`;
		elt.textContent = this.name.replace('{', '').replace('}', '');
		return elt;
	}

	override ignoreEvent() {
		return false;
	}
}

@Component({
	selector: 'app-transaction-field-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './transaction-field-dialog.component.html',
	styleUrls: ['./transaction-field-dialog.component.scss'],
})
export class TransactionFieldDialogComponent extends BaseDialogComponent {
	transactionFieldForm = new FormGroup({
		name: new FormControl('', [Validators.required]),
		dataFormat: new FormControl(Format.DECIMAL, Validators.required),
		fieldType: new FormControl(FieldType.INPUT, Validators.required),
		formula: new FormControl('', Validators.required),
		defaultValue: new FormControl(''),
		description: new FormControl(''),
		products: new FormControl<string[]>([]),
	});
	DATA_TYPES = Object.values(Format);
	FIELD_TYPES = Object.values(FieldType);
	FieldType = FieldType;
	view: EditorView;
	sideFieldFilter = '';

	@ViewChild('formulaEditor') formulaEditor: any;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			transactionField: TransactionField;
			transactionFields: TransactionField[];
		},
		@Inject(DOCUMENT) private document: Document,
		public transactionFieldService: TransactionFieldService
	) {
		super();
		this.errors$ = this.transactionFieldService.errors$.pipe(map(error => error.payload.data.error.error));
		this.id = data?.transactionField?.id;
		if (this.id) {
			this.transactionFieldForm.patchValue({
				name: data.transactionField.name,
				formula: data.transactionField.formula,
				fieldType: data.transactionField.fieldType,
				dataFormat: data.transactionField.dataFormat,
				defaultValue: data.transactionField.defaultValue,
				description: data.transactionField.description,
				products: data.transactionField.products,
			});
		}
	}

	ngAfterViewInit(): void {
		let myExtensions = [
			basicSetup,
			spreadsheet(),
			EditorView.updateListener.of((evEvent: any) => {
				// Workaround to put cursor after dropped field
				try {
					if (evEvent.changes?.inserted.length > 0) {
						if (evEvent.changes?.inserted.some((ins: any[]) => ins.length > 0)) {
							if (
								evEvent.state.selection.ranges &&
								evEvent.state.selection.ranges[0].from !== evEvent.state.selection.ranges[0].to
							) {
								this.view.dispatch({
									selection: { anchor: evEvent.state.selection.ranges[0].to },
								});
							}
						}
					}
				} catch (error) {
					// Ignore
				}
			}),
		];
		if (this.tfNames.length > 0) {
			myExtensions.push(this.preparePlaceholders());
		}

		const state = EditorState.create({
			doc: this.transactionFieldForm.controls.formula.value,
			extensions: myExtensions,
		});

		this.view = new EditorView({
			state: state,
			parent: this.formulaEditor.nativeElement,
		});
	}

	preparePlaceholders() {
		const placeholderMatcher = new MatchDecorator({
			regexp: new RegExp(`(${this.tfNames})`, 'g'),
			decoration: match =>
				Decoration.replace({
					widget: new PlaceholderWidget(match[1]),
				}),
		});
		return ViewPlugin.fromClass(
			class {
				placeholders: DecorationSet;

				constructor(view: EditorView) {
					this.placeholders = placeholderMatcher.createDeco(view);
				}

				update(update: ViewUpdate) {
					this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders);
				}
			},
			{
				decorations: instance => instance.placeholders,
				provide: plugin =>
					EditorView.atomicRanges.of(view => {
						return view.plugin(plugin)?.placeholders || Decoration.none;
					}),
			}
		);
	}

	get name() {
		return this.transactionFieldForm.get('name');
	}

	get dataFormat() {
		return this.transactionFieldForm.get('dataFormat');
	}

	get fieldType() {
		return this.transactionFieldForm.get('fieldType');
	}

	get defaultValue() {
		return this.transactionFieldForm.get('defaultValue');
	}

	get formula() {
		return this.transactionFieldForm.get('formula');
	}

	get products() {
		return this.transactionFieldForm.get('products');
	}

	get tfNames() {
		return this.data.transactionFields
			.filter(
				// Get only fields available for current product
				tf => tf.products.filter(product => this.products.value.includes(product)).length > 0
			)
			.map(tf => `{${tf.name}}`)
			.join('|');
	}

	get fieldsExcludingThisOne() {
		return this.data.transactionFields.filter(
			tf => tf.id !== this.id && tf.name.toLowerCase().includes(this.sideFieldFilter)
		);
	}

	saveTransactionField() {
		this.errors = [];
		if (this.fieldType.value === FieldType.ALGORITHM) {
			this.transactionFieldForm.patchValue({
				formula: this.view.state.doc.toString(),
			});
		}
		this.transactionFieldForm.markAllAsTouched();
		if (this.transactionFieldForm.valid) {
			if (this.id) {
				this.transactionFieldService.update({
					...this.transactionFieldForm.getRawValue(),
					id: this.id,
				});
			} else {
				this.transactionFieldService.add(this.transactionFieldForm.getRawValue());
			}
		}
	}

	public dragstartHandler(event: any) {
		event.dataTransfer.setData('text/plain', event.target.dataset.dragData);
	}

	protected readonly Products = Products;
	protected readonly Object = Object;

	nameFilterChange($event: Event) {
		// @ts-ignore
		this.sideFieldFilter = $event.target.value.trim().toLowerCase();
	}
}
