import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-base-unsub',
	template: ``,
})
export abstract class BaseUnsubscibeComponent implements OnDestroy {
	unsubscribe$: Subject<void> = new Subject<void>();

	destroySubs(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete()
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
