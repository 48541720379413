import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICountry, ICountryByIp } from '@cm-shared/interfaces/country.intf';

import { BehaviorSubject, filter, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountriesHttpService {
  private allCountries$ = new BehaviorSubject<Array<ICountry>>([]);
	private allCountriesMapByIso$ = new BehaviorSubject<Record<string, ICountry>>({});
  private countryByIp$ = new BehaviorSubject<ICountryByIp | null>(null);
  private isCountryListFetching = false;
  private isCountryListFetched = false;
  private isCountryByIpFetching = false;
  private isCountryByIpFetched = false;

  get ipAddress(): string {
    return this.countryByIp$?.value?.ip;
  }
  constructor(private http: HttpClient) {}

	selectCountriesMapByIso(): Observable<Record<string, ICountry>> {
		if (!this.isCountryListFetched && !this.isCountryListFetching) {
      this.fetchCountriesData().subscribe();
    }
		return this.allCountriesMapByIso$.asObservable().pipe(filter(r => Boolean(Object.keys(r).length)))
	}

  private fetchCountriesData(): Observable<Array<ICountry>> {
    this.isCountryListFetching = true;
    return this.http
      .get<Array<ICountry>>(`${document.baseURI}assets/countries.json`)
      .pipe(
        tap(list => {
          this.isCountryListFetching = false;
          this.isCountryListFetched = true;
          const allList = list
            ?.filter(i => i.name)
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
					const map = allList.reduce((acc, i) => {
						acc[i.code.toLowerCase()] = i
						return acc
					}, {} as Record<string, ICountry>)
					this.allCountriesMapByIso$.next(map);
          this.allCountries$.next(allList);
        }),
      );
  }

  public selectAllCountries(): Observable<Array<ICountry>> {
    if (!this.isCountryListFetched && !this.isCountryListFetching) {
      this.fetchCountriesData().subscribe();
    }
    return this.allCountries$.asObservable().pipe(filter(r => Boolean(r.length)));
  }

  private fetchUserCountryByIp(): void {
    this.isCountryByIpFetching = true;
    this.http.get<ICountryByIp>('https://ipapi.co/json').subscribe({
      next: r => {
        this.isCountryByIpFetching = false;
        this.isCountryByIpFetched = true;
        this.countryByIp$.next(r);
      },
      error: () => {
        // todo ?
      },
    });
  }

  public selectUserCountryByIp(): Observable<ICountryByIp | null> {
    if (!this.isCountryByIpFetched && !this.isCountryByIpFetching) {
      this.fetchUserCountryByIp();
    }
    return this.countryByIp$.asObservable().pipe(filter(r => Boolean(r)));
  }
}
