import { ApiService } from '../../../.api-client/services/api.service';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadUserInfo } from './user.actions';

@Injectable()
export class UserEffects {
	loadUserInfo$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadUserInfo),
				tap(action =>
					this.api
						.usersControllerMyself({
							body: {
								oid: action.oid,
								email: action.email,
							},
						})
						.subscribe()
				)
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private api: ApiService) {}
}
