import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@cm-env/environment';
import { InvestorData, InvestorDataCreate } from '@cm-shared/interfaces/investor-data.intf';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvestorDataHttpService {
  constructor(private http: HttpClient) {}

  public create(payload: InvestorDataCreate): Observable<InvestorData> {
		return this.http.post<InvestorData>(`${environment.apiUrl}/api/investordata`, payload)
	}

	public remove(id: string): Observable<void> {
		return this.http.delete<void>(`${environment.apiUrl}/api/investordata/${id}`)
	}
}
