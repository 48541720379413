import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CmSpinnerModule } from '../spinner/spinner.component';

@Component({
  selector: 'app-btn-wrap',
  templateUrl: './btn-wrap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnWrapComponent {
  @Input() btnType: 'btn' | 'link' = 'btn';
  @Input() isLoading: boolean;
}

@NgModule({
  declarations: [BtnWrapComponent],
  imports: [CommonModule, CmSpinnerModule],
  exports: [BtnWrapComponent],
})
export class BtnWrapModule {}
