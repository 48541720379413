import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LedgersComponent } from './ledgers/ledgers.component';
import { MaterialModule } from '../material.module';
import { LedgerDialogComponent } from './ledger-dialog/ledger-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JournalsComponent } from './journals/journals.component';
import { JournalDialogComponent } from './journal-dialog/journal-dialog.component';
import { LedgerDetailsComponent } from './ledger-details/ledger-details.component';

@NgModule({
	declarations: [
		LedgersComponent,
		LedgerDialogComponent,
		JournalsComponent,
		JournalDialogComponent,
		LedgerDetailsComponent,
	],
	imports: [CommonModule, MaterialModule, ReactiveFormsModule],
})
export class ChartOfAccountsModule {}
