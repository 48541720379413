import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractBaseComponent } from '@cm-shared/abstract/abstract-base';
import { debounceTime, takeUntil } from 'rxjs';
import { InvestorsHttpService } from '@cm-shared/services/investors-http.service';
import { IInvestorForSelect } from '@cm-shared/interfaces/investor.intf';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { IInvestor } from 'credebt-shared';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';


@Component({
  selector: 'app-investor-selector',
  templateUrl: './investor-selector.component.html',
  styleUrls: ['./investor-selector.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class InvestorSelectorComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
	@Input() fetchFullData = true; // fetch full data after selection and after init
	@Input() formCtrl!: FormControl; // MUST be set id of investor
	@Input() parentForm!: FormGroup;
	@Input() formCtrlName!: string;
	@Input() autoFocus: boolean;
	@Input() autoFocusDelayMs = 500;

	private allOptions: Array<IInvestorForSelect>;
	public filteredOptions: Array<IInvestorForSelect>;
	public investorSearchCtrl = new FormControl();

	@ViewChild('investorSelector') investorSelector: MatAutocomplete;
	@ViewChild('investorSelectorInput') investorSelectorInput: ElementRef<HTMLInputElement>;
	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	get currId(): string {
		return this.formCtrl?.value;
	}

	@Output() fullDataFetched = new EventEmitter<IInvestor>();

	constructor(
		private investorsHttpS: InvestorsHttpService
	) {
		super()
	}

	ngOnInit(): void {
		this.fetchAllInvestors();
		this.initSearchInvestor();
		this.initDefaultInvestor();
		if (this.autoFocus) {
			setTimeout(() => {
				this.investorSelectorInput.nativeElement.focus()
			}, this.autoFocusDelayMs)
		}
	}

	ngOnDestroy(): void {
		super.destroySubscriptions()
	}

	private initSearchInvestor(): void {
		this.investorSearchCtrl.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(r => {
			if (typeof r !== 'object') {
				this.filteredOptions = r
				? this.allOptions.filter(i => i.name.toLowerCase().includes(r.toLowerCase()) || i.id.includes(r.toLowerCase()))
				: this.allOptions;
			}
		})
	}

	scrollToSelectedIndex(): void {
		const id = this.formCtrl.value;
		if (id) {
			const index = this.filteredOptions.findIndex(i => i.id === id);
			if(index > -1){
				this.viewPort.scrollToIndex(index);
			}
		}

	}

	private initDefaultInvestor(): void {
		const investorId = this.formCtrl.value;
		if (investorId) {
			this.fetchDetails(investorId)
		}
	}

	private fetchAllInvestors(): void {
		this.investorsHttpS.fetchListForSelect().subscribe(r => {
			r.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			this.allOptions = r;
			this.filteredOptions = r;
		})
	}

	displayFn(v: IInvestorForSelect): string {
		return v ? v.name + ' ' + v.id : ''
	}

	selected(id: string): void {
		this.formCtrl.setValue(id);
		if (this.fetchFullData) {
			this.fetchDetails(id);
		}
	}

	fetchDetails(id: string): void {
		this.investorsHttpS.fetchById(id).pipe(takeUntil(this.destroy$)).subscribe(r => {
			this.fullDataFetched?.emit(r);
		});
	}

	clearSelectedInvestor(): void {
		this.viewPort.scrollToIndex(0)
		this.investorSearchCtrl.setValue('');
		this.formCtrl.setValue(undefined);
	}
}
