<div class="entity-container">
	<div class="flex justify-between space-x-8 mb-4">
		<app-xero></app-xero>

		<app-importer></app-importer>

		<button
			mat-raised-button
			(click)="showAddEditTransaction = true">
			Create Transaction
		</button>

		<app-create-edit-transaction
			*ngIf="showAddEditTransaction"
			(createdInvestorData)="createdInvestorData($event)"
			(closed)="showAddEditTransaction = false">
		</app-create-edit-transaction>
	</div>

	<h1>Investor Data</h1>

	<div class="entity-actions">
		<button (click)="refresh()" mat-mini-fab color="primary" aria-label="Refresh data">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="" #input />
	</mat-form-field>

	<div class="table-container">
		<div class="loading-shade" *ngIf="loading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="investor">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Investor</th>
					<td mat-cell *matCellDef="let row">{{ row.investor }}</td>
				</ng-container>

				<ng-container matColumnDef="transactionType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
					<td mat-cell *matCellDef="let row">{{ row.transactionType }}</td>
				</ng-container>

				<ng-container matColumnDef="ccy">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Ccy</th>
					<td mat-cell *matCellDef="let row">{{ row.ccy }}</td>
				</ng-container>

				<ng-container matColumnDef="faceValue">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Face Value</th>
					<td mat-cell *matCellDef="let row" class="text-right">
						{{ row.faceValue | currency : 'EUR' : '' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
					<td mat-cell *matCellDef="let row">{{ row.date }}</td>
				</ng-container>

				<ng-container matColumnDef="buyRate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Buy Rate</th>
					<td mat-cell *matCellDef="let row" class="text-center">{{ showPercent(row.buyRate) }}</td>
				</ng-container>

				<ng-container matColumnDef="commission">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
					<td mat-cell *matCellDef="let row" class="text-center">{{ showPercent(row.commission) }}</td>
				</ng-container>

				<ng-container matColumnDef="months">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Months</th>
					<td mat-cell *matCellDef="let row" class="text-center">
						{{ row.months }}
					</td>
				</ng-container>

				<ng-container matColumnDef="investmentType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
					<td mat-cell *matCellDef="let row">{{ row.investmentType }}</td>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<!-- <button (click)="edit($event, row.id)" mat-mini-fab color="primary" aria-label="Edit Transaction Model">
							<mat-icon>edit</mat-icon>
						</button> -->
						<app-btn-wrap [isLoading]="deleteId === row.id">
							<button (click)="removeData($event, row.id)" mat-mini-fab color="warn" aria-label="Delete Transaction Model">
								<mat-icon>delete</mat-icon>
							</button>
						</app-btn-wrap>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</div>
</div>
