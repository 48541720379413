import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LedgerService } from '../ledger.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ledger } from '../store/ledger.model';
import { BaseDialogComponent } from '../../shared/base-dialog/base-dialog.component';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-ledger-dialog',
	templateUrl: './ledger-dialog.component.html',
	styleUrls: ['./ledger-dialog.component.scss'],
})
export class LedgerDialogComponent extends BaseDialogComponent {
	ledgerForm = new FormGroup({
		name: new FormControl('', [Validators.required]),
		parent: new FormControl('', Validators.required),
	});

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { ledgers: Ledger[]; id: string | null },
		public ledgerService: LedgerService
	) {
		super();
		this.errors$ = this.ledgerService.errors$.pipe(map(error => error.payload.data.error.error));
		this.id = data?.id;
		if (this.id) {
			const ledger = data.ledgers.find(l => l.id === data.id);
			this.ledgerForm.setValue({
				name: ledger.name,
				parent: ledger.parentLedger.id,
			});
		}
	}

	get name() {
		return this.ledgerForm.get('name');
	}
	get parent() {
		return this.ledgerForm.get('parent');
	}

	saveLedger() {
		this.errors = [];
		this.ledgerForm.markAllAsTouched();
		if (this.ledgerForm.valid) {
			if (this.id) {
				this.ledgerService.update({
					...this.ledgerForm.getRawValue(),
					id: this.id,
				});
			} else {
				this.ledgerService.add(this.ledgerForm.getRawValue());
			}
		}
	}
}
