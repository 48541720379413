import { Component, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionModelService } from '../transaction-model.service';
import { TransactionModelModel } from '../store/transaction-model.model';
import { BaseDialogComponent } from '../../shared/base-dialog/base-dialog.component';
import { InvestorTransactionType, Products } from 'credebt-shared';

@Component({
	selector: 'app-transaction-model-dialog',
	templateUrl: './transaction-model-dialog.component.html',
	styleUrls: ['./transaction-model-dialog.component.scss'],
})
export class TransactionModelDialogComponent extends BaseDialogComponent {
	transactionModelForm = new FormGroup({
		name: new FormControl('', [Validators.required]),
		ref: new FormControl('', Validators.required),
		product: new FormControl('', Validators.required),
		transactionTypes: new FormControl<string[]>([]),
		description: new FormControl(''),
	});

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			transactionModel: TransactionModelModel;
		},
		public transactionModelService: TransactionModelService
	) {
		super();
		this.errors$ = this.transactionModelService.errors$.pipe(map(error => error.payload.data.error.error));
		this.id = data?.transactionModel.id;
		if (this.id) {
			this.transactionModelForm.setValue({
				name: data.transactionModel.name,
				ref: data.transactionModel.ref,
				product: data.transactionModel.product,
				transactionTypes: data.transactionModel.transactionTypes ?? [],
				description: data.transactionModel.description ?? '',
			});
		}
	}

	get name() {
		return this.transactionModelForm.get('name');
	}

	get ref() {
		return this.transactionModelForm.get('ref');
	}

	get product() {
		return this.transactionModelForm.get('product');
	}

	get description() {
		return this.transactionModelForm.get('description');
	}

	saveTransactionModel() {
		this.errors = [];
		console.log(this.transactionModelForm.getRawValue());
		this.transactionModelForm.markAllAsTouched();
		if (this.transactionModelForm.valid) {
			if (this.id) {
				this.transactionModelService.update({
					...this.transactionModelForm.getRawValue(),
					id: this.id,
				});
			} else {
				this.transactionModelService.add(this.transactionModelForm.getRawValue());
			}
		}
	}

	protected readonly Products = Products;
	protected readonly Object = Object;
	protected readonly InvestorTransactionType = InvestorTransactionType;
}
