import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UiBreakpointsService } from '@cm-core/services/ui-breakpoints.service';
import { AbstractBaseComponent } from '@cm-shared/abstract/abstract-base';
import { AnimateInOpacityFromBottom } from '@cm-shared/animations/in-opacity-from-bottom.anim';
import { AnimateInOpacityFromTop } from '@cm-shared/animations/in-opacity-from-top.anim';
import { FORM_ERRORS } from '@cm-shared/validations/form-control-errors.enum';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { TooltipModule } from '../tooltip/tooltip.module';
import { InputClearBtnModule } from '../input-clear-btn/input-clear-btn.component';
import { InputErrorModule } from '../input-error/input-error.component';

import * as intlTelInput from 'intl-tel-input';
import { BehaviorSubject, debounceTime, filter, takeUntil } from 'rxjs';

function ItiValidator(iti: intlTelInput.Plugin): ValidatorFn {
  return (): ValidationErrors | null => {
    if (!iti.isValidNumber()) {
      return { [FORM_ERRORS.PHONE]: String(iti.getValidationError()) };
    } else {
      return null;
    }
  };
}

export interface IPhoneResult {
  fullPhone: string;
  withoutDialCode: string;
  dialCode: string;
  iso2: string;
}

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  animations: [
    AnimateInOpacityFromBottom('150ms'),
    AnimateInOpacityFromTop('300ms'),
  ],
})
export class PhoneComponent
  extends AbstractBaseComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  @Input() formCtrl!: AbstractControl;
  @Input() countryFormCtrl!: AbstractControl;
  @Input() disabled: boolean;
  @Input() showIntPhone: boolean;
  @Input() autodetectedCountryIso$: BehaviorSubject<string>;

  public isFocused: boolean;
  private iti: intlTelInput.Plugin;
  public isIntlPhoneDefined: boolean;
  public reinit = true;
  @Output() changed = new EventEmitter<string>();
  @Output() keydownEnterClicked = new EventEmitter<any>();

  @HostBinding('class') get classes(): string {
    return [
      'ib-phone',
      this.isFocused ? 'ib-phone--focused' : '',
      this.formCtrl?.invalid ? 'ib-phone--invalid' : '',
      this.formCtrl?.touched ? 'ib-phone--touched' : '',
      this.disabled ? 'ib-phone--disabled' : '',
    ].join(' ');
  }

  get inputHtmlEl(): HTMLInputElement {
    return this.telInput?.nativeElement;
  }
  get fullData(): IPhoneResult | null {
    if (this.iti) {
      const countryData = this.iti.getSelectedCountryData();
      return {
        fullPhone: this.iti.getNumber(),
        withoutDialCode: this.iti
          .getNumber()
          .replace('+' + countryData.dialCode, ''),
        dialCode: countryData.dialCode,
        iso2: countryData.iso2,
      };
    } else {
      return null;
    }
  }

  @ViewChild('telInput', { static: false })
  telInput: ElementRef<HTMLInputElement>;

  constructor(public bpS: UiBreakpointsService) {
    super();
  }

  ngOnInit(): void {
    this.formCtrl?.valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(v => {
        this.isIntlPhoneDefined =
          this.formCtrl.valid && this.iti.isValidNumber();
        if (!v) {
          this.telInput.nativeElement.value = '';
        } else {
				}
      });
  }

  ngOnDestroy(): void {
    this.iti.destroy();
    super.destroySubscriptions();
  }

  keydownEnterClick(e: any): void {
    this.keydownEnterClicked?.emit(e);
  }

  ngAfterViewInit(): void {
    this.init();
  }

  private init(): void {
    this.initItiLib();
    this.initValidators();
    setTimeout(() => {
      this.setInitialValue();
      this.isIntlPhoneDefined = this.formCtrl.valid && this.iti.isValidNumber();
    }, 300);
  }

  private setInitialValue(): void {
    this.iti.setNumber(this.formCtrl?.value);
    // this.iti.setCountry('ua');
  }

  focus(): void {
    this.isFocused = true;
  }

  keyup(): void {
    let itiValue = this.inputHtmlEl.value;
    itiValue = itiValue.replace(/[!@#$%^&*?><"':;']/, '');
    itiValue = itiValue.replace(/[a-zA-Z]/, '');
    this.inputHtmlEl.value = itiValue;
    const d = this.fullData?.fullPhone;
    this.formCtrl.setValue(d);
    this.countryFormCtrl.setValue(this.fullData.iso2);
    this.changed?.emit(d);
  }

  public blur(): void {
    this.isFocused = false;
    this.formCtrl.markAsTouched();
    this.formCtrl.markAsDirty();
  }

  private initValidators(): void {
    this.formCtrl.addValidators([ItiValidator(this.iti)]);
  }

  private initItiLib(): void {
    this.iti = intlTelInput(this.telInput.nativeElement, {
      initialCountry: this.formCtrl.value ? undefined : 'auto',
      preferredCountries: this.formCtrl.value ? [] : ['ie', 'gb'], // todo - enums
      geoIpLookup: callback => {
        this.autodetectedCountryIso$?.asObservable()
          .pipe(
            filter(r => Boolean(r)),
            takeUntil(this.destroy$),
          )
          .subscribe({
            next: res => {
              if (!this.countryFormCtrl.value) {
                callback(res ? res.toLowerCase() : 'ie');
              }
            },
            error: () => {
              if (!this.countryFormCtrl.value) {
                callback('ie');
              }
            },
          });
      },
      utilsScript: '/assets/libs/intl-tel-input-utils.js',
    });
  }
}

@NgModule({
  declarations: [PhoneComponent],
  imports: [
    CommonModule,
    InputClearBtnModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    InputErrorModule,
    TooltipModule,
  ],
  exports: [PhoneComponent],
})
export class PhoneModule {}
