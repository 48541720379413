import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUnsubscibeComponent } from '../../shared/base-unsubscibe/base-unsubscibe.component';

@Component({
	selector: 'app-xero-callback',
	templateUrl: './xero-callback.component.html',
	styleUrls: ['./xero-callback.component.scss'],
})
export class XeroCallbackComponent extends BaseUnsubscibeComponent implements OnInit {
	constructor(private activatedRoute: ActivatedRoute, private router: Router) {
		super();
	}

	ngOnInit() {
		if (window.opener) {
			this.activatedRoute.queryParams.subscribe(params => {
				window.opener.postMessage(params, location.origin);
				window.close();
			});
		} else {
			this.router.navigate(['dashboard'], { skipLocationChange: true });
		}
	}
}
