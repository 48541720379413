import { Component } from '@angular/core';
import { skipUntil } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { GENERIC_ERROR_MSG } from '../../constants';
import { BaseUnsubscibeComponent } from '../base-unsubscibe/base-unsubscibe.component';

@Component({
	selector: 'app-base-dialog',
	template: ``,
})
export abstract class BaseDialogComponent extends BaseUnsubscibeComponent {
	errors$: Observable<any>;
	errors: string[] = [];
	id: string = null;

	ngOnInit(): void {
		this.errors$.pipe(skipUntil(timer(0))).subscribe(error => {
			if (error.status === 400) {
				this.errors = this.errors.concat(error.error.message);
			} else if (error.status === 500) {
				this.errors = this.errors.concat(GENERIC_ERROR_MSG);
			}
		});
	}
}
