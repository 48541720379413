import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validatorPattern(
  regex: RegExp,
  error: ValidationErrors,
): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
}
