import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionModelsComponent } from './transaction-models/transaction-models.component';
import { TransactionModelDialogComponent } from './transaction-model-dialog/transaction-model-dialog.component';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransactionModelDetailsComponent } from './transaction-model-details/transaction-model-details.component';
import { TransactionFieldsComponent } from './transaction-fields/transaction-fields.component';
import { TransactionFieldDialogComponent } from './transaction-field-dialog/transaction-field-dialog.component';
import { JournalLineDialogComponent } from './journal-line-dialog/journal-line-dialog.component';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { ImporterComponent } from './importer/importer.component';
import { InvestorDataComponent } from './investor-data/investor-data.component';
import { InvestorTransactionsComponent } from './investor-transactions/investor-transactions.component';
import { ToolsModule } from '../tools/tools.module';
import { CreateEditTransactionComponent } from './investor-data/create-edit-transaction/create-edit-transaction.component';
import { SharedModule } from '@cm-shared/shared.module';
import { FormControlComponent } from './investor-data/create-edit-transaction/form-control/form-control.component';

@NgModule({
	declarations: [
		TransactionModelsComponent,
		TransactionModelDialogComponent,
		TransactionModelDetailsComponent,
		TransactionFieldsComponent,
		TransactionFieldDialogComponent,
		JournalLineDialogComponent,
		ImporterComponent,
		InvestorDataComponent,
		InvestorTransactionsComponent,
		CreateEditTransactionComponent,
		FormControlComponent,
	],
	imports: [
		SharedModule,
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		CdkDrag,
		CdkDropList,
		FormsModule,
		HttpClientModule,
		ToolsModule,
	],
})
export class TransactionsModule {}
