import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XeroComponent } from './xero/xero.component';
import { XeroCallbackComponent } from './xero-callback/xero-callback.component';
import { MaterialModule } from '../material.module';

@NgModule({
	declarations: [XeroComponent, XeroCallbackComponent],
	imports: [CommonModule, MaterialModule],
	exports: [XeroComponent],
})
export class ToolsModule {}
