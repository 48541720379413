<h2 mat-dialog-title>
	<span *ngIf="id; else create">Edit</span>
	<ng-template #create>New</ng-template>
	Journal Line
</h2>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="journalLineForm" class="flex-v">
		<mat-form-field>
			<mat-label>Journal</mat-label>
			<mat-select formControlName="journalId" required>
				<mat-option *ngFor="let journal of data.journals" [value]="journal.id">
					{{ journal.code }} - {{ journal.name }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="journalId.hasError('required')">Journal is required.</mat-error>
		</mat-form-field>

		<div id="fieldRef">
			<span class="square input-square"></span>
			- Input
			<span class="square alg-square"></span>
			- Algorithm
		</div>

		<mat-form-field>
			<mat-label>Field</mat-label>
			<input type="text" aria-label="Field" matInput formControlName="transactionField" [matAutocomplete]="auto" />
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
				<mat-option
					[ngClass]="{
						input: tf.fieldType === FieldType.INPUT,
						algorithm: tf.fieldType === FieldType.ALGORITHM
					}"
					*ngFor="let tf of filteredOptions | async"
					[value]="tf">
					{{ tf.name }}
					<span class="dataFormat">({{ tf.dataFormat | titlecase }})</span>
				</mat-option>
			</mat-autocomplete>
			<mat-error *ngIf="journalId.hasError('required')">Transaction Fields is required.</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Use as</mat-label>
			<mat-select formControlName="type" required>
				<mat-option *ngFor="let jlt of journalLineTypes" [value]="jlt">
					{{ jlt }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="type.hasError('required')">Use as is required.</mat-error>
		</mat-form-field>

		<div *ngIf="errors.length > 0" class="mat-mdc-form-field-error">
			<div *ngFor="let error of errors">{{ error }}</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-button (click)="saveJournalLine()">Save</button>
</mat-dialog-actions>
