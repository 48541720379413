import { Directive } from '@angular/core';

import { Subject } from 'rxjs';

@Directive()
export abstract class AbstractBaseComponent {
  protected destroy$ = new Subject<void>();

  destroySubscriptions(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
