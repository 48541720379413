<div class="entity-container">
	<h1>Models</h1>

	<div class="entity-actions">
		<button (click)="newEntity()" mat-raised-button [disabled]="!(loaded$ | async)">New Model</button>

		<button (click)="refresh()" mat-mini-fab color="primary" aria-label="Refresh data">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="" #input />
	</mat-form-field>

	<div class="table-container">
		<div class="loading-shade" *ngIf="loading$ | async">
			<mat-spinner></mat-spinner>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="ref" matSortDirection="asc">
				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let row">{{ row.name }}</td>
				</ng-container>

				<ng-container matColumnDef="ref">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Ref</th>
					<td mat-cell *matCellDef="let row">{{ row.ref }}</td>
				</ng-container>

				<ng-container matColumnDef="product">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
					<td mat-cell *matCellDef="let row">{{ row.product }}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
					<td mat-cell *matCellDef="let row">{{ row.description }}</td>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Actions</th>
					<td mat-cell *matCellDef="let row">
						<button (click)="edit($event, row.id)" mat-mini-fab color="primary" aria-label="Edit Transaction Model">
							<mat-icon>edit</mat-icon>
						</button>
						<button (click)="delete($event, row.id)" mat-mini-fab color="warn" aria-label="Delete Transaction Model">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row (click)="openTransactionModel(row)" *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</div>
</div>
