<div class="flex">
	<div id="dialogContent">
		<h2 mat-dialog-title>
			<span *ngIf="id; else create">Edit</span>
			<ng-template #create>New</ng-template>
			Field
		</h2>
		<mat-dialog-content class="mat-typography">
			<form [formGroup]="transactionFieldForm" class="flex-v">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input matInput id="name" name="name" required formControlName="name" />
					<mat-error *ngIf="name.hasError('required')">Name is required.</mat-error>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Products</mat-label>
					<mat-select formControlName="products" multiple>
						<mat-option *ngFor="let product of Object.values(Products)" [value]="product">{{ product }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Format</mat-label>
					<mat-select formControlName="dataFormat" required>
						<mat-option *ngFor="let dt of DATA_TYPES" [value]="dt">
							{{ dt | titlecase }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="dataFormat.hasError('required')">Format is required.</mat-error>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Field Type</mat-label>
					<mat-select formControlName="fieldType" required>
						<mat-option *ngFor="let ft of FIELD_TYPES" [value]="ft">
							{{ ft | titlecase }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="fieldType.hasError('required')">Field Type is required.</mat-error>
				</mat-form-field>

				<div class="formula-field" *ngIf="fieldType.value === FieldType.INPUT">
					<mat-form-field id="formulaFormField">
						<mat-label>{{ fieldType.value | titlecase }}</mat-label>
						<input matInput required id="column" name="formula" formControlName="formula" />
						<mat-error *ngIf="formula.hasError('required')">{{ fieldType.value | titlecase }} is required.</mat-error>
					</mat-form-field>
				</div>

				<div class="formula-field" [hidden]="fieldType.value !== FieldType.ALGORITHM">
					<mat-form-field>
						<textarea
							hidden="hidden"
							matInput
							required
							id="formula"
							name="formula"
							formControlName="formula"></textarea>
						<div class="droptarget" #formulaEditor></div>

						<mat-error *ngIf="formula.hasError('required')">{{ fieldType.value | titlecase }} is required.</mat-error>
					</mat-form-field>
				</div>

				<mat-form-field>
					<mat-label>Default Value</mat-label>
					<input matInput id="defaultValue" name="defaultValue" formControlName="defaultValue" />
				</mat-form-field>

				<mat-form-field>
					<mat-label>Description</mat-label>
					<textarea matInput id="description" name="description" formControlName="description"></textarea>
				</mat-form-field>

				<div *ngIf="errors.length > 0" class="mat-mdc-form-field-error">
					<div *ngFor="let error of errors">{{ error }}</div>
				</div>
			</form>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<button mat-button mat-dialog-close>Cancel</button>
			<button mat-button (click)="saveTransactionField()">Save</button>
		</mat-dialog-actions>
	</div>
	<div id="fieldList">
		<h4>Available Fields:</h4>

		<div id="fieldRef">
			<span class="square input-square"></span>
			- Input
			<span class="square alg-square"></span>
			- Algorithm
		</div>

		<mat-form-field>
			<mat-label>Filter Name</mat-label>
			<input matInput (keyup)="nameFilterChange($event)" placeholder="" #input />
		</mat-form-field>

		<div id="fieldListContent">
			<div
				matTooltipPosition="right"
				[matTooltip]="field.products.join(', ')"
				[attr.data-drag-data]="'{' + field.name + '}'"
				[ngClass]="{
					input: field.fieldType === FieldType.INPUT,
					algorithm: field.fieldType === FieldType.ALGORITHM
				}"
				draggable="true"
				(dragstart)="dragstartHandler($event)"
				*ngFor="let field of fieldsExcludingThisOne">
				{{ field.name }}
			</div>
		</div>
	</div>
</div>
