import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PASSWORD_SPECIFIC_SYMBOLS } from '@cm-enums/validators-password';
import { AnimateInOpacityFromTop } from '@cm-shared/animations/in-opacity-from-top.anim';
import { EMAIL_VALIDATION_PATTERN } from '@cm-shared/validations/email-pattern';
import { FORM_ERRORS } from '@cm-shared/validations/form-control-errors.enum';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';



@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  animations: [AnimateInOpacityFromTop('200ms')],
})
export class InputErrorComponent {
  @Input() formCtrl?: AbstractControl;
  @Input() hideErrors?: Array<FORM_ERRORS>;
  @Input() customErrors?: string;

  public readonly FORM_ERRORS = FORM_ERRORS;
  public readonly PASSWORD_SPECIFIC_SYMBOLS = PASSWORD_SPECIFIC_SYMBOLS;

  get isEmailInput(): boolean {
    const validators: ValidationErrors = this.formCtrl.validator(this.formCtrl);
    return (
      validators?.['email'] ||
      validators?.['pattern']?.['requiredPattern'] === EMAIL_VALIDATION_PATTERN
    );
  }

  get isEmailFormatError(): boolean {
    return (
      this.isEmailInput &&
      ((this.formCtrl.errors[FORM_ERRORS.EMAIL] &&
        !this.hideErrors?.includes(FORM_ERRORS.EMAIL)) ||
        this.formCtrl.errors?.['pattern'])
    );
  }

  get isRequiredError(): boolean {
    return this.formCtrl?.errors?.[FORM_ERRORS.REQUIRED];
  }
}

@NgModule({
  declarations: [InputErrorComponent],
  imports: [CommonModule],
  exports: [InputErrorComponent],
})
export class InputErrorModule {}
