import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractBaseComponent } from '@cm-shared/abstract/abstract-base';
import { Observable, map, of, take, takeUntil, tap } from 'rxjs';
import { TransactionFieldService } from '../../transaction-field.service';
import { IInvestor, getDbFieldName,  INVESTOR_FIELD_NAME, ISelectOption, IInvestorData, InvestorTransactionType } from 'credebt-shared';
import { TransactionFieldExpanded } from '../../store/transaction-field.model';
import { IInvestorForSelect } from '@cm-shared/interfaces/investor.intf';
import { TRANSACTION_TYPE_OPTIONS } from '@cm-shared/configs/investment-type-options.config';
import { INVESTMENT_TYPE_OPTIONS } from '@cm-shared/configs/transaction-type-options.config';
import { IntermediariesHttpService } from '@cm-shared/services/Intermediaries-http.service';
import { CountriesHttpService } from '@cm-shared/services/countries-data.service';
import { InvestorDataHttpService } from '@cm-shared/services/investor-data-http.service';
import { InvestorDataCreate } from '@cm-shared/interfaces/investor-data.intf';
import { DateTimeFormatService } from '@cm-shared/services/date-time-format.service';
import { NotZeroValidator } from '@cm-shared/validations/not-a-zero.validator';

enum CUSTOM_FIELDS {
	INVESTOR = 'investor',
	ADDRESS = 'address',
	MONTHS = 'months',
}

@Component({
  selector: 'app-create-edit-transaction',
  templateUrl: './create-edit-transaction.component.html',
  styleUrls: ['./create-edit-transaction.component.scss'],
})
export class CreateEditTransactionComponent extends AbstractBaseComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() invData: IInvestorData

	public form: FormGroup;
	public readonly FIELD_NAME = INVESTOR_FIELD_NAME;
	public readonly TRANSACTION_TYPE_OPTIONS = TRANSACTION_TYPE_OPTIONS
	public readonly INVESTMENT_TYPE_OPTIONS = INVESTMENT_TYPE_OPTIONS;
	public readonly CUSTOM_FIELDS = CUSTOM_FIELDS;
	private intermidiaries: Array<ISelectOption>
	public intermidiaries$= this.intermediariesHttpS.fetchListForSelect().pipe(tap(r => {
		this.intermidiaries = r;
	}));
	public autoFocusInvSelector = false;
	public isEditMode = false;
	public monthsOptions: Array<ISelectOption>;
	private dialogRef: MatDialogRef<any>;
	public loading = false;
	public investorFormCtrl = new FormControl(null, [Validators.required]);
	private currInvestor: IInvestor;
	private disabledInvestorBankFields = [
		INVESTOR_FIELD_NAME.Account_Holder,
		INVESTOR_FIELD_NAME.Bank_Account,
		INVESTOR_FIELD_NAME.Bank_Name,
		INVESTOR_FIELD_NAME.SWIFT_BIC,
		INVESTOR_FIELD_NAME.Sort_Code,
		INVESTOR_FIELD_NAME.IBAN,
		INVESTOR_FIELD_NAME.Last_Name,
		INVESTOR_FIELD_NAME.First_Name,
		INVESTOR_FIELD_NAME.Email,
		INVESTOR_FIELD_NAME.Mobile_Number,
		INVESTOR_FIELD_NAME.Phone_Number,
		INVESTOR_FIELD_NAME.Date_Of_Birth,
		CUSTOM_FIELDS.ADDRESS
	]
	public showDetails = true;

	@Output() closed = new EventEmitter<void>();
	@Output() createdInvestorData = new EventEmitter<IInvestorData>();

	@ViewChild('tmpl') tmpl: TemplateRef<any>;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		public transactionFieldService: TransactionFieldService,
		private intermediariesHttpS: IntermediariesHttpService,
		private countriesS: CountriesHttpService,
		private investorDataHttpS: InvestorDataHttpService,
		private dateTimeS: DateTimeFormatService
	) {
		super()
	}

	ngOnInit(): void {
		this.initMode()
		this.initMonths()
		this.createForm()
	}

	ngOnDestroy(): void {
		this.dialogRef.close();
		super.destroySubscriptions()
	}

	ngAfterViewInit(): void {
		this.openModal();
	}

	private initMode(): void {
		this.isEditMode = Boolean(this.invData);
		if (!this.isEditMode) {
			this.autoFocusInvSelector = true;
		}
	}

	private initMonths(): void {
		this.monthsOptions = [6,12,24,36,48,60,72,84,96,108,120].map(i => ({label: String(i), value: i}))
	}

	investorDataFetched(inv: IInvestor): void {
		this.currInvestor = inv;
		Object.keys(this.form.controls).forEach(formKey => {
			if (this.disabledInvestorBankFields.includes(formKey as INVESTOR_FIELD_NAME)) {
				const dbFieldName = getDbFieldName(formKey as INVESTOR_FIELD_NAME);
				let val;
				if (dbFieldName === 'phone' || dbFieldName === 'mobilePhone') {
					val = (inv as any)[dbFieldName]?.phone;
					this.form.get(formKey)?.setValue(val || '-');
				} else if (dbFieldName === 'dob') {
					let val = (inv as any)[dbFieldName];
					if (val?.length === 10 && val[4] === '/') {
						const regex = new RegExp('/', "g");
						val = val.replace(regex, '-')
					}
					this.form.get(formKey)?.setValue(val || null);
				} else {
					val = (inv as any)[dbFieldName]
					this.form.get(formKey)?.setValue(val || '-')
				}
			}
		})
		// set inv address
		this.getAddress(inv).pipe(take(1), takeUntil(this.destroy$)).subscribe(address => {
			this.form.get(CUSTOM_FIELDS.ADDRESS).setValue(address)
			this.disableFields();
		})
	}

	submit(): void {
		if (this.form.valid) {
			this.loading = true;
		this.investorDataHttpS
			.create(this.createPayload()).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.createdInvestorData?.emit(res);
				this.loading = false;
			},
			error: err => {
				console.error(err);
				this.loading = false;
				// todo
			}
		})
		} else {
			this.form.markAllAsTouched();
		}

	}

	private createPayload(): InvestorDataCreate {
		const intermidiary = this.intermidiaries.find(i => i.value === this.form.get(INVESTOR_FIELD_NAME.Intermediary).value)
		return {
			investor: this.currInvestor?.id,
			intermediaryId: intermidiary?.value,
			intermediary: intermidiary?.label,
			date: this.dateTimeS.transformUiDateFormatToApiFormat(this.form.get(INVESTOR_FIELD_NAME.Date)?.value),
			faceValue: this.form.get(INVESTOR_FIELD_NAME.Face_Value)?.value,
			ccy: 'EUR',
			buyRate: String((this.form.get(INVESTOR_FIELD_NAME.Buy_Rate)?.value as number)/100),
			commission: String((this.form.get(INVESTOR_FIELD_NAME.Commission)?.value as number)/100),
			months: this.form.get(CUSTOM_FIELDS.MONTHS)?.value,
			transactionType: this.form.get(INVESTOR_FIELD_NAME.TransactionType)?.value,
			investmentType: this.form.get(INVESTOR_FIELD_NAME.Investment_Type)?.value,
		}
	}

	private getAddress(inv: IInvestor): Observable<string> {
		const getFullAddress = (country?: string) => {
			return [
				inv.postalZip,
				country,
				inv.city,
				inv.streetAndNumber
			].filter(i => i).join(', ') || '-'
		}
		if (inv.countryCode) {
			return this.countriesS.selectCountriesMapByIso()
				.pipe(
					map(r => {
						return getFullAddress(r[inv.countryCode.toLowerCase()].name)
					})
				)
		} else {
			return of(getFullAddress())
		}
	}

	submitFromInput(e: Event): void {
		e.stopPropagation();
    e.preventDefault();
    if (this.form.valid) {
      this.submit();
    } else {
      this.form.markAllAsTouched();
    }
	}

	private isDisabledField(v: INVESTOR_FIELD_NAME): boolean {
		return this.disabledInvestorBankFields.includes(v)
	}

	private disableFields(): void {
		Object.keys(this.form.controls).forEach(field => {
			if (this.isDisabledField(field as INVESTOR_FIELD_NAME)) {
				this.form.get(field).disable()
			}
		})
	}

	displayFn(v: IInvestorForSelect): string {
		return v ? v.name + ' ' + v.id : ''
	}

	private createForm(): void {
		this.form = this.fb.group({

			// bank
			[INVESTOR_FIELD_NAME.Account_Holder]: [null, []],
			[INVESTOR_FIELD_NAME.Bank_Account]: [null, []],
			[INVESTOR_FIELD_NAME.Bank_Name]: [null, []],
			[INVESTOR_FIELD_NAME.SWIFT_BIC]: [null, []],
			[INVESTOR_FIELD_NAME.Sort_Code]: [null, []],
			[INVESTOR_FIELD_NAME.IBAN]: [null, []],

			// investor
			[INVESTOR_FIELD_NAME.Last_Name]: [null, []],
			[INVESTOR_FIELD_NAME.First_Name]: [null, []],
			[INVESTOR_FIELD_NAME.Email]: [null, []],
			[INVESTOR_FIELD_NAME.Mobile_Number]: [null, []],
			[INVESTOR_FIELD_NAME.Phone_Number]: [null, []],
			[INVESTOR_FIELD_NAME.Date_Of_Birth]: [null, []],
			[CUSTOM_FIELDS.ADDRESS]: [null, []],

			// transaction
			[INVESTOR_FIELD_NAME.TransactionType]: [null, [Validators.required]],
			[INVESTOR_FIELD_NAME.Investment_Type]: [null, [Validators.required]],
			[INVESTOR_FIELD_NAME.Intermediary]: [null, [Validators.required]],
			[INVESTOR_FIELD_NAME.Face_Value]: [null, [Validators.required]],
			[INVESTOR_FIELD_NAME.Buy_Rate]: [null, [Validators.required, Validators.min(0.01), Validators.max(99.99)]],
			[INVESTOR_FIELD_NAME.Commission]: [null, [Validators.required, Validators.min(0.01), Validators.max(99.99)]],
			[INVESTOR_FIELD_NAME.Processing_Commission]: ['15 EUR', []],
			[INVESTOR_FIELD_NAME.Date]: [null, [Validators.required]],
			[CUSTOM_FIELDS.MONTHS]: [null, [Validators.required]],
		})
		this.form.get(INVESTOR_FIELD_NAME.Processing_Commission).disable();
		this.form.addControl(CUSTOM_FIELDS.INVESTOR, this.investorFormCtrl)
		this.form.get(INVESTOR_FIELD_NAME.TransactionType).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(type => {
			this.initFaceValByTransType(type);
		})
		this.disableFields();
	}

	private initFaceValByTransType(type: InvestorTransactionType): void {
		const ctrl = this.form.get(INVESTOR_FIELD_NAME.Face_Value);
		ctrl.setValidators([Validators.required])
		if (type === InvestorTransactionType.RPAReceipt) {
			ctrl.setValidators([
				Validators.required,
				Validators.min(0.01)
			])
		} else if (type === InvestorTransactionType.Adjustment) {
			ctrl.setValidators([
				Validators.required,
				NotZeroValidator()
			])
		} else if (type === InvestorTransactionType.Redemption) {
			ctrl.setValidators([
				Validators.required,
				Validators.max(-0.01)
			])
		}
		ctrl.updateValueAndValidity();
	}

	private openModal(): void {
		this.dialogRef = this.dialog.open(this.tmpl, {
			width: '90vw',
			autoFocus: false,
			position: {
				top: '50px'
			}
		});
		this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.closed?.emit()
		})
	}
}
