import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TransactionModelService } from '../transaction-model.service';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { TransactionModelModel } from '../store/transaction-model.model';
import { filter, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JournalLineDialogComponent } from '../journal-line-dialog/journal-line-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { JournalLine, JournalLineType } from '../store/journal-line.model';
import { JournalLineService } from '../journal-line.service';
import { JournalService } from '../../chart-of-accounts/journal.service';
import { Journal } from '../../chart-of-accounts/store/journal.model';
import { DEFAULT_MODAL_WIDTH, isNumber, JOURNAL_LINE } from '../../constants';
import { EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransactionFieldService } from '../transaction-field.service';
import { TransactionField } from '../store/transaction-field.model';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { ApiService } from '../../../.api-client/services/api.service';

enum Modes {
	EDIT = 'Edit',
	TEST = 'Test',
}

interface TestJournalLine extends JournalLine {
	debit?: string;
	credit?: string;
	posting?: string;
}

@Component({
	selector: 'app-transaction-model-details',
	templateUrl: './transaction-model-details.component.html',
	styleUrls: ['./transaction-model-details.component.scss'],
})
export class TransactionModelDetailsComponent extends BaseListComponent implements OnInit {
	transactionModel$: Observable<TransactionModelModel>;
	journals$ = this.journalService.entities$;
	journalLines$ = this.jlService.filteredEntities$;
	transactionFields$ = this.tfService.entities$;
	journals: Journal[];
	journalLines: TestJournalLine[];
	transactionFields: TransactionField[];
	loading$ = this.tmService.loading$;
	loaded$ = this.tmService.loaded$;
	jlLoading$ = this.jlService.loading$;
	jlLoaded$ = this.jlService.loaded$;
	transactionModel: TransactionModelModel;
	override dialogRef: MatDialogRef<JournalLineDialogComponent>;
	override dataSource: MatTableDataSource<JournalLine>;
	testDataForm: FormGroup = new FormGroup([]);
	tmId: string;
	verifying$ = new BehaviorSubject(false);
	errors = 0;
	ok = 0;
	noChange = 0;
	total = 0;
	lastDate = '';

	displayedColumns: string[];
	sharedColumns: string[] = [
		'order',
		'journal',
		'code',
		'type',
		'transactionField',
		'transactionFieldType',
		'transactionFieldDataFormat',
	];
	editColumns: string[] = [...this.sharedColumns, 'actions'];
	testColumns: string[] = [...this.sharedColumns, 'debit', 'credit', 'posting'];
	mode = Modes.EDIT;

	constructor(
		public tmService: TransactionModelService,
		public tfService: TransactionFieldService,
		public jlService: JournalLineService,
		public journalService: JournalService,
		public api: ApiService,
		dialog: MatDialog,
		snackBar: MatSnackBar,
		private http: HttpClient,
		private route: ActivatedRoute,
		private changeDetectorRefs: ChangeDetectorRef
	) {
		super(jlService, dialog, snackBar);
		this.displayedColumns = this.editColumns;
	}

	prepareActionEvents() {
		this.jlService.entityActions$
			.pipe(
				takeUntil(this.unsubscribe$),
				ofEntityType(JOURNAL_LINE),
				ofEntityOp([EntityOp.SAVE_ADD_ONE_SUCCESS, EntityOp.SAVE_UPDATE_ONE_SUCCESS])
			)
			.subscribe(() => {
				this.tmService.getByKey(this.tmId);
				this.dialogRef.close();
				this.snackBar.open('Object saved successfully!', 'Dismiss', {
					duration: 5000,
				});
			});
		this.jlService.entityActions$
			.pipe(takeUntil(this.unsubscribe$), ofEntityType(JOURNAL_LINE), ofEntityOp([EntityOp.SAVE_DELETE_ONE_SUCCESS]))
			.subscribe(() => {
				this.tmService.getByKey(this.tmId);
			});
	}

	prepareSubscriptions() {
		this.journalLines$.pipe(takeUntil(this.unsubscribe$)).subscribe(jls => {
			this.journalLines = jls.map(jl => {
				return { ...jl, debit: '0', credit: '0', posting: '0' };
			});
			this.dataSource = new MatTableDataSource(this.journalLines);
			this.dataSource.sort = this.sort;
		});
		this.transactionModel$ = this.tmService.entities$.pipe(
			takeUntil(this.unsubscribe$),
			map(tms => tms.find(tm => tm.id === this.tmId))
		);
		this.transactionModel$
			.pipe(
				takeUntil(this.unsubscribe$),
				filter(tm => tm?.fieldsForTesting !== undefined)
			)
			.subscribe(tm => {
				this.transactionModel = tm;
				for (const field of tm.fieldsForTesting) {
					this.testDataForm.addControl(field.id, new FormControl());
				}
				this.tfService.getWithQuery({ product: tm.product });
				this.transactionFields$.pipe(takeUntil(this.unsubscribe$)).subscribe(tfs => {
					this.transactionFields = tfs.filter(tf => tf.products.includes(tm.product));
				});
			});
		this.journals$.pipe(takeUntil(this.unsubscribe$)).subscribe(journals => {
			this.journals = journals;
		});
	}

	ngOnInit() {
		this.prepareActionEvents();
		this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: Params) => {
			this.tmId = params['id'];
			this.jlService.setFilter({ transactionModelId: this.tmId });
			this.prepareSubscriptions();
			this.tmService.getByKey(this.tmId);
			this.journalService.getAll();
			this.jlService.getWithQuery({ transactionModel: this.tmId });
		});
	}

	newJournalLine() {
		this.dialogRef = this.dialog.open(JournalLineDialogComponent, {
			width: DEFAULT_MODAL_WIDTH,
			data: {
				transactionModelId: this.transactionModel.id,
				journals: this.journals,
				transactionFields: this.transactionFields,
			},
		});
	}

	override edit($event: MouseEvent, id: string) {
		$event.stopPropagation();
		this.dialogRef = this.dialog.open(JournalLineDialogComponent, {
			width: DEFAULT_MODAL_WIDTH,
			data: {
				transactionModelId: this.transactionModel.id,
				journals: this.journals,
				journalLines: this.journalLines,
				transactionFields: this.transactionFields,
				id,
			},
		});
		return false;
	}

	override delete($event: MouseEvent, id: string) {
		$event.stopPropagation();
		this.jlService.delete(id, { isOptimistic: false });
		return false;
	}

	onModeChange(newMode: Modes) {
		if (newMode === Modes.TEST) {
			this.displayedColumns = this.testColumns;
		} else {
			this.displayedColumns = this.editColumns;
		}
	}

	protected readonly Modes = Modes;
	protected readonly Object = Object;

	get totalDebit() {
		return this.journalLines.map(jl => +jl.debit).reduce((partialSum, d) => partialSum + d, 0);
	}

	get totalCredit() {
		return this.journalLines.map(jl => +jl.credit).reduce((partialSum, d) => partialSum + d, 0);
	}

	get totalPosting() {
		return this.journalLines.map(jl => +jl.posting).reduce((partialSum, d) => partialSum + d, 0);
	}

	updateResult() {
		this.http
			.post(`${environment.apiUrl}/api/transactionmodel/test`, {
				inputs: this.testDataForm.getRawValue(),
				transactionModel: this.tmId,
			})
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((res: any) => {
				this.dataSource.connect().next(
					this.journalLines.map(jl => {
						const field = res['testValues'].find((tv: { id: string }) => tv.id === jl.transactionField.id);
						if (jl.type === JournalLineType.DEBIT) {
							jl.debit = field.testValue;
							jl.posting = `-${Math.abs(field.testValue)}`;
						} else {
							jl.credit = field.testValue;
							jl.posting = `+${Math.abs(field.testValue)}`;
						}
						return jl;
					})
				);
				this.changeDetectorRefs.detectChanges();
			});
	}

	verifyAll() {
		this.verifying$.next(true);
		this.api
			.importerControllerVerifyAll({ id: this.tmId })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: res => {
					try {
						// @ts-ignore
						const resultsJson = JSON.parse(res);
						const updatedJls = this.journalLines.map(jl => {
							// @ts-ignore
							const fieldValue = resultsJson[jl.id];
							if (jl.type === JournalLineType.DEBIT) {
								jl.debit = fieldValue;
								jl.posting = `-${fieldValue}`;
							} else {
								jl.credit = fieldValue;
								jl.posting = `+${fieldValue}`;
							}
							return jl;
						});
						this.errors = resultsJson.errors;
						this.ok = resultsJson.ok;
						this.noChange = resultsJson.noChange;
						this.total = resultsJson.total;
						this.lastDate = resultsJson.lastDate[0].max;
						this.dataSource.connect().next(updatedJls);
						this.changeDetectorRefs.detectChanges();
					} catch (e) {
						console.error(e);
					}
					this.verifying$.next(false);
				},
				error: error => {
					console.log(error);
					this.verifying$.next(false);
				},
			});
	}

	protected readonly isNumber = isNumber;
}
