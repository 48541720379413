
<form [formGroup]="parentForm">
	<app-mat-form-field-wrap
		[formCtrlName]="formCtrlName"
		[formCtrl]="parentForm.get(formCtrlName)"
		>
		<mat-form-field class="w-full">
			<mat-label>Select Investor:</mat-label>
			<input
				#investorSelectorInput
				type="text"
				class="app-investor-selector__input"
				[formControl]="investorSearchCtrl"
				matInput
				[matAutocomplete]="auto" />
			<mat-autocomplete
				#investorSelector
				class="app-investor-selector__autocomplete"
				#auto="matAutocomplete"
				[displayWith]="displayFn"
				(opened)="scrollToSelectedIndex()"
				(optionSelected)="selected($event.option.value.id)">
				<cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}"  itemSize="50" minBufferPx="500" maxBufferPx="750">
					<mat-option
						[ngClass]="{'font-semibold': investor.id === currId}"
						*cdkVirtualFor="let investor of filteredOptions"
						[value]="investor"
						style="height: 50px;">
						<div class="flex justify-between items-center">
							<span class="text-ellipsis">{{ investor.name }}</span><span class="ml-2">{{ investor.id }}</span>
						</div>
					</mat-option>
				</cdk-virtual-scroll-viewport>
			</mat-autocomplete>
			<button *ngIf="investorSearchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelectedInvestor()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</app-mat-form-field-wrap>
</form>

	<!-- <app-input-error class="grow ml-8px text-11px mt-8px" [formCtrl]="formCtrl">
	</app-input-error> -->
