import { Journal } from '../../chart-of-accounts/store/journal.model';
import { TransactionModelModel } from './transaction-model.model';
import { TransactionField } from './transaction-field.model';

export enum JournalLineType {
	DEBIT = 'Debit',
	CREDIT = 'Credit',
}

export interface JournalLine {
	id?: string;
	journal?: Journal;
	transactionModel?: TransactionModelModel;
	transactionField?: TransactionField;
	transactionFieldId?: string;
	order?: number;
	type: JournalLineType;
}
