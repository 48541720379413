<ng-template #tmpl>
	<div mat-dialog-title>Create Transaction</div>

  <div mat-dialog-content>
		<!-- [hidden]="!showInvSelector" -->
		<div class="flex justify-between items-center" >
			<app-investor-selector
				[formCtrl]="investorFormCtrl"
				[parentForm]="form"
				[autoFocus]= "autoFocusInvSelector"
				[formCtrlName]="CUSTOM_FIELDS.INVESTOR"
				class="flex-grow mr-10"
				(fullDataFetched)="investorDataFetched($event)">
			</app-investor-selector>
		</div>

		<form [formGroup]="form" class="py-10">

			<section>
				<div class="font-semibold">Transaction</div>

				<div class="grid grid-cols-2 lg:grid-cols-2 gap-3">
					<div class="grid grid-cols-2 lg:grid-cols-2 gap-3">
						<app-mat-form-field-wrap
							[formCtrlName]="FIELD_NAME.TransactionType"
							[formCtrl]="form.get(FIELD_NAME.TransactionType)"
							>
							<mat-form-field class="app-mat-form-field">
								<mat-label>{{FIELD_NAME.TransactionType}}</mat-label>
								<mat-select formControlName="{{FIELD_NAME.TransactionType}}" [required]="true">
									<mat-option *ngFor="let option of TRANSACTION_TYPE_OPTIONS" [value]="option.value">
										{{option.label}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</app-mat-form-field-wrap>

						<app-mat-form-field-wrap
								[formCtrlName]="FIELD_NAME.Investment_Type"
								[formCtrl]="form.get(FIELD_NAME.Investment_Type)"
							>
							<mat-form-field class="app-mat-form-field">
								<mat-label>{{FIELD_NAME.Investment_Type}}</mat-label>
								<mat-select formControlName="{{FIELD_NAME.Investment_Type}}" [required]="true">
									<mat-option *ngFor="let option of INVESTMENT_TYPE_OPTIONS" [value]="option.value">
										{{option.label}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</app-mat-form-field-wrap>
					</div>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Intermediary"
						[formCtrl]="form.get(FIELD_NAME.Intermediary)"

						>
						<mat-form-field class="app-mat-form-field">
							<mat-label>Intermediary</mat-label>
							<mat-select formControlName="{{FIELD_NAME.Intermediary}}" [required]="true">
								<mat-option *ngFor="let option of intermidiaries$ | async" [value]="option.value">
									{{option.label}}&nbsp;{{option.value}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</app-mat-form-field-wrap>
				</div>

				<div class="grid grid-cols-6 lg:grid-cols-6 gap-3 mt-3">
					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Face_Value"
						[formCtrl]="form.get(FIELD_NAME.Face_Value)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Face_Value}}, EUR</mat-label>
							<input
								matInput
								type="number"
								formControlName="{{FIELD_NAME.Face_Value}}"
								[required]="true"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Buy_Rate"
						[formCtrl]="form.get(FIELD_NAME.Buy_Rate)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Buy_Rate}}, %</mat-label>
							<input
								matInput
								type="number"
								min="0.01"
								step="any"
								max="99.99"
								formControlName="{{FIELD_NAME.Buy_Rate}}"
								[required]="true"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Date"
						[formCtrl]="form.get(FIELD_NAME.Date)"
						[hideErrors]="pickerTransDate.opened"
					>
						<mat-form-field  class="app-mat-form-field" (click)="pickerTransDate.opened ? pickerTransDate.close() : pickerTransDate.open()">
							<mat-label>{{FIELD_NAME.Date}}</mat-label>
							<input
								matInput
								[matDatepicker]="pickerTransDate"
								(focus)="pickerTransDate.open()"
								[readonly]="true"
								formControlName="{{FIELD_NAME.Date}}"
								placeholder="YYYY-MM-dd"
							/>
							<mat-datepicker-toggle
								matIconSuffix
								[for]="pickerTransDate"
							></mat-datepicker-toggle>
							<mat-datepicker #pickerTransDate></mat-datepicker>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="CUSTOM_FIELDS.MONTHS"
						[formCtrl]="form.get(CUSTOM_FIELDS.MONTHS)"
						>
						<mat-form-field class="app-mat-form-field">
							<mat-label>Months</mat-label>
							<mat-select formControlName="{{CUSTOM_FIELDS.MONTHS}}" [required]="true">
								<mat-option *ngFor="let option of monthsOptions" [value]="option.value">
									{{option.label}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Commission"
						[formCtrl]="form.get(FIELD_NAME.Commission)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Commission}}, %</mat-label>
							<input
								matInput
								type="number"
								min="0.01"
								step="any"
								max="99.99"
								formControlName="{{FIELD_NAME.Commission}}"
								[required]="true"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Processing_Commission"
						[formCtrl]="form.get(FIELD_NAME.Processing_Commission)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>Processing Comm.</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Processing_Commission}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>


				</div>
			</section>

			<hr class="my-14">

			<button
				type="button"
				mat-raised-button
				(click)="showDetails = !showDetails">
				{{!showDetails ? 'Show' : 'Hide'}} Investor Details
			</button>

			<section *ngIf="showDetails" class="mt-10">
				<div class="font-semibold">Investor</div>
				<div class="grid grid-cols-2 lg:grid-cols-3 gap-3">

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.First_Name"
						[formCtrl]="form.get(FIELD_NAME.First_Name)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>First Name</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.First_Name}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Last_Name"
						[formCtrl]="form.get(FIELD_NAME.Last_Name)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>Last Name</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Last_Name}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Email"
						[formCtrl]="form.get(FIELD_NAME.Email)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>Email</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Email}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Phone_Number"
						[formCtrl]="form.get(FIELD_NAME.Phone_Number)"
					>
						<mat-form-field  class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Phone_Number}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Phone_Number}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Mobile_Number"
						[formCtrl]="form.get(FIELD_NAME.Mobile_Number)"
					>
						<mat-form-field  class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Mobile_Number}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Mobile_Number}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Date_Of_Birth"
						[formCtrl]="form.get(FIELD_NAME.Date_Of_Birth)"
						[hideErrors]="pickerInvestorDob.opened"
					>
						<mat-form-field  class="app-mat-form-field" (click)="pickerInvestorDob.opened ? pickerInvestorDob.close() : pickerInvestorDob.open()">
							<mat-label>{{FIELD_NAME.Date_Of_Birth}}</mat-label>
							<input
								matInput
								[matDatepicker]="pickerInvestorDob"
								(focus)="pickerInvestorDob.open()"
								[readonly]="true"
								formControlName="{{FIELD_NAME.Date_Of_Birth}}"
								placeholder="YYYY-MM-dd"
							/>
							<mat-datepicker-toggle
								matIconSuffix
								[for]="pickerInvestorDob"
							></mat-datepicker-toggle>
							<mat-datepicker #pickerInvestorDob></mat-datepicker>
						</mat-form-field>
					</app-mat-form-field-wrap>
				</div>

				<app-mat-form-field-wrap
					class="mt-3"
					[formCtrlName]="CUSTOM_FIELDS.ADDRESS"
					[formCtrl]="form.get(CUSTOM_FIELDS.ADDRESS)"
				>
					<mat-form-field  class="app-mat-form-field">
						<mat-label>Address</mat-label>
						<input
							matInput
							type="text"
							formControlName="{{CUSTOM_FIELDS.ADDRESS}}"
							(keydown.enter)="submitFromInput($event)"
					/>
					</mat-form-field>
				</app-mat-form-field-wrap>
			</section>

			<section *ngIf="showDetails" class="mt-10">
				<div class="font-semibold">Bank Details</div>
				<div class="grid grid-cols-2 lg:grid-cols-3 gap-3">
					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Account_Holder"
						[formCtrl]="form.get(FIELD_NAME.Account_Holder)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Account_Holder}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Account_Holder}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Bank_Account"
						[formCtrl]="form.get(FIELD_NAME.Bank_Account)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Bank_Account}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Bank_Account}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Bank_Name"
						[formCtrl]="form.get(FIELD_NAME.Bank_Name)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Bank_Name}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Bank_Name}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.SWIFT_BIC"
						[formCtrl]="form.get(FIELD_NAME.SWIFT_BIC)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.SWIFT_BIC}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.SWIFT_BIC}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.Sort_Code"
						[formCtrl]="form.get(FIELD_NAME.Sort_Code)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.Sort_Code}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.Sort_Code}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>

					<app-mat-form-field-wrap
						[formCtrlName]="FIELD_NAME.IBAN"
						[formCtrl]="form.get(FIELD_NAME.IBAN)"
					>
						<mat-form-field class="app-mat-form-field">
							<mat-label>{{FIELD_NAME.IBAN}}</mat-label>
							<input
								matInput
								type="text"
								formControlName="{{FIELD_NAME.IBAN}}"
								(keydown.enter)="submitFromInput($event)"
						/>
						</mat-form-field>
					</app-mat-form-field-wrap>
				</div>
			</section>
		</form>
  </div>
	<div mat-dialog-actions>
		<div class="flex justify-end items-center flex-grow p-3">
			<button
				mat-raised-button
				class="mr-3"
				mat-dialog-close>
				Cancel
			</button>
			<app-btn-wrap [isLoading]="loading">
				<button
					mat-raised-button
					[disabled]="loading"
					color="primary"
					(click)="submit()">
					Create
				</button>
			</app-btn-wrap>

		</div>
	</div>

</ng-template>

