import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor(private oidcSecurityService: OidcSecurityService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Apply the headers
		this.oidcSecurityService.getAccessToken().subscribe(token => {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		});

		// Also handle errors globally
		return next.handle(req).pipe(
			tap(
				x => x,
				err => {
					// Handle this err
					console.error(`Error performing request, status code = ${err.status}`);
				}
			)
		);
	}
}
