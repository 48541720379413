import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: [
				{
					configId: 'AD',
					authority: 'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/v2.0',
					authWellknownEndpoints: {
						tokenEndpoint: 'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/oauth2/v2.0/token',
						jwksUri: 'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/discovery/v2.0/keys',
						issuer: 'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/v2.0',
						userInfoEndpoint: 'https://graph.microsoft.com/oidc/userinfo',
						authorizationEndpoint:
							'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/oauth2/v2.0/authorize',
						endSessionEndpoint:
							'https://login.microsoftonline.com/7653f2c8-b98b-4cb3-8f2d-4e0b7c7a9e4b/oauth2/v2.0/logout',
					},
					redirectUrl: window.location.origin,
					postLogoutRedirectUri: window.location.origin,
					clientId: '0fc85ba3-d853-4993-9185-b8c0912d53e6',
					scope: 'openid profile email offline_access api://0fc85ba3-d853-4993-9185-b8c0912d53e6/machine',
					// Check https://learn.microsoft.com/en-us/azure/active-directory/develop/scopes-oidc
					responseType: 'code',
					silentRenew: true,
					useRefreshToken: true,
					ignoreNonceAfterRefresh: true,
					maxIdTokenIatOffsetAllowedInSeconds: 600,
					issValidationOff: false,
					autoUserInfo: false,
					historyCleanupOff: false,
					silentRenewUrl: window.location.origin + '/silent-renew.html',
					// logLevel: LogLevel.Debug, // Enable for Debugging Auth
					customParamsAuthRequest: {
						prompt: 'select_account', // login, consent
					},
				},
				{
					configId: 'Xero',
					authority: 'https://login.xero.com/identity/connect/authorize',
					authWellknownEndpoints: {
						issuer: 'https://login.xero.com/identity/connect/authorize',
						authorizationEndpoint: 'https://login.xero.com/identity/connect/authorize',
					},
					redirectUrl: 'http://localhost:4200/xeroCallback',
					postLogoutRedirectUri: window.location.origin,
					clientId: 'C147BAC4A0B7441C953197DDA5E141B9',
					responseType: 'code',
					scope: 'openid profile email offline_access',
					logLevel: LogLevel.Debug,
					disableIdTokenValidation: true,
					issValidationOff: true,
				},
			],
		}),
	],
	exports: [AuthModule],
})
export class AuthConfigModule {}
