import { Component, OnDestroy } from '@angular/core';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvestorDataService } from '../investor-data.service';
import { takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { InvestorData } from '../store/investor-data.model';
import { showPercent } from '../../constants';
import { IInvestorData } from 'credebt-shared';
import { InvestorDataHttpService } from '@cm-shared/services/investor-data-http.service';
import { environment } from '@cm-env/environment';

@Component({
	selector: 'app-investor-data',
	templateUrl: './investor-data.component.html',
	styleUrls: ['./investor-data.component.scss'],
})
export class InvestorDataComponent extends BaseListComponent {
	deleteId: string;
	loading$ = this.investorTransactionService.loading$;
	loaded$ = this.investorTransactionService.loaded$;
	investorTransactions$ = this.investorTransactionService.entities$;
	investorTransactions: InvestorData[];
	displayedColumns: string[] = [
		'investor',
		'date',
		'faceValue',
		'ccy',
		'buyRate',
		'commission',
		'months',
		'transactionType',
		'investmentType',
		'actions'
	];
	public showAddEditTransaction = false;

	constructor(public investorTransactionService: InvestorDataService, dialog: MatDialog, snackBar: MatSnackBar, private investorDataHttpS: InvestorDataHttpService) {
		super(investorTransactionService, dialog, snackBar);
		this.investorTransactions$.pipe(takeUntil(this.unsubscribe$)).subscribe(investorTransactions => {
			// this.investorTransactions = environment.production ? investorTransactions : investorTransactions?.slice(0, 10);
			this.investorTransactions =  investorTransactions;
			this.dataSource = new MatTableDataSource(this.investorTransactions);
			this.dataSource.sort = this.sort;
		});
		this.investorTransactionService.getAll();
	}

	protected readonly showPercent = showPercent;

	removeData(e: Event, id: string): void {
		this.deleteId = id;
		this.delete(e, id)
	}

	createdInvestorData(e: IInvestorData): void {
		this.showAddEditTransaction = false
		this.investorTransactionService.getAll();
	}
}
