import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, merge, Observable, of, switchMap, takeUntil } from 'rxjs';
import { BaseUnsubscibeComponent } from '@cm-shared/base-unsubscibe/base-unsubscibe.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '@cm-env/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { InvestorSelect, isNumber, showPercent } from '../../constants';

@Component({
	selector: 'app-investor-transactions',
	templateUrl: './investor-transactions.component.html',
	styleUrls: ['./investor-transactions.component.scss'],
})
export class InvestorTransactionsComponent extends BaseUnsubscibeComponent implements AfterViewInit {
	displayedColumns: string[] = [
		'index',
		'investorId',
		'intermediaryID',
		'intermediary',
		'investorName',
		'investorFirstName',
		'investorLastName',
		'investorEmail',
		'investorDateOfBrith',
		'investorAddress1',
		'investorAddress2',
		'investorPhone',
		'investorMobilePhone',
		'bankName',
		'bankSortCode',
		'bankAccount',
		'bankSwiftBic',
		'bankIban',
		'date',
		'buyRate',
		'transactionType',
		'ccy',
		'faceValue',
		'commission',
		'commissionResult',
		'yield',
		'months',
		'investmentType',
		'balance',
		'eurCommission',
		'bankExchange',
		'eurBankExchange',
		'marginCall',
	];
	investors: InvestorSelect[] = [];
	data: any[] = [];
	investor = new FormControl<InvestorSelect>(null);
	intermediary = new FormControl('');

	pageSize = 1000;
	resultsLength = 0;
	isLoadingResults = true;
	filteredOptions: Observable<InvestorSelect[]>;
	ALL = { name: 'All', id: 'All' };

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatAutocomplete) auto: MatAutocomplete;

	constructor(private http: HttpClient, private changeDetectorRefs: ChangeDetectorRef) {
		super();
	}

	ngAfterViewInit() {
		this.http
			.get(`${environment.apiUrl}/api/investorids`)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: investors => {
					this.investors = investors as InvestorSelect[];
					this.filteredOptions = this.investor.valueChanges.pipe(
						startWith(''),
						map(value => {
							const filterValue = typeof value === 'string' ? value : value?.name;
							return this.investors.filter(
								inv =>
									inv.name.toLowerCase().includes(filterValue.toLowerCase()) ||
									inv.id.toString().includes(filterValue.toLowerCase())
							);
						})
					);
				},
			});

		// If the user changes the sort order, reset back to the first page.
		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(
			this.sort.sortChange,
			this.paginator.page,
			this.auto.optionSelected,
			this.intermediary.valueChanges.pipe(debounceTime(1000))
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					if (!this.isLoadingResults && this.investorVal) {
						this.isLoadingResults = true;
						return this.getData(
							this.investorVal.id,
							this.intermediaryVal,
							this.sort.active,
							this.sort.direction,
							this.paginator.pageIndex + 1
						).pipe(catchError(() => of(null)));
					} else {
						return of(null);
					}
				}),
				map((result: any) => {
					// Flip flag to show that loading has finished.
					this.isLoadingResults = false;

					if (result === null) {
						return [];
					}
					// Only refresh the result length if there is new data. In case of rate
					// limit errors, we do not want to reset the paginator to zero, as that
					// would prevent users from re-triggering requests.
					this.resultsLength = result.meta.itemCount;
					return result.data;
				})
			)
			.subscribe(data => {
				this.data = data;
				this.changeDetectorRefs.detectChanges();
			});
		this.investor.setValue(this.ALL);
		this.sort.sort({ id: 'date', start: 'desc' } as MatSortable);
	}

	getData(investorId: string, intermediaryVal: string, sort?: string, direction?: string, page?: number) {
		return this.http.get(
			`${environment.apiUrl}/api/fullinvestortransactions/${investorId}?take=${this.pageSize}&intermediary=${intermediaryVal}&sort=${sort}&direction=${direction}&page=${page}`
		);
	}

	displayFn(inv: InvestorSelect): string {
		return inv && inv.name ? inv.name : '';
	}

	get investorVal() {
		return this.investor.value;
	}

	get intermediaryVal() {
		return this.intermediary.value;
	}

	totals(fieldName: string): number {
		return this.data.map(invData => _.get(invData, fieldName)).reduce((prev, curr) => prev + parseFloat(curr), 0);
	}

	csvExport() {
		const workSheet = XLSX.utils.json_to_sheet(
			this.data.map(row => {
				return {
					data_id: row.id,
					investorId: row.investor.id,
					intermediaryId: row.intermediaryId,
					date: row.date,
					buyRate: row.buyRate,
					transactionType: row.transactionType,
					ccy: row.ccy,
					faceValue: row.faceValue,
					commission: row.commission,
					commissionResult: row.partial.commissionResult,
					yield: row.partial.yield,
					months: row.months,
					investmentType: row.investmentType,
					balance: row.partial.balance,
				};
			})
		);
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Transactions');
		XLSX.writeFile(workBook, 'investor_transactions.csv');
	}

	protected readonly isNumber = isNumber;
	protected readonly showPercent = showPercent;
}
