<div class="grid-container">
	<h1 class="mat-h1">Dashboard</h1>
	<mat-grid-list cols="2" rowHeight="350px">
		<mat-grid-tile>
			<mat-card class="dashboard-card">
				<mat-card-header>
					<mat-card-title>Example request to NestJS</mat-card-title>
				</mat-card-header>
				<mat-card-actions align="end">
					<button (click)="testError()" mat-raised-button>Test Error</button>
				</mat-card-actions>
			</mat-card>
		</mat-grid-tile>
	</mat-grid-list>
</div>
