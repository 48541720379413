import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Journal } from './store/journal.model';
import { JOURNAL } from '../constants';

@Injectable({ providedIn: 'root' })
export class JournalService extends EntityCollectionServiceBase<Journal> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(JOURNAL, serviceElementsFactory);
	}
}
