<ng-content></ng-content>

<ng-container *ngIf="isLoading">
  <div
    *ngIf="btnType === 'btn'"
    class="absolute bg-white top-0 right-0 bottom-0 left-0 flex justify-center items-center z-10"
  >
    <app-spinner [diameter]="15"></app-spinner>
  </div>
  <app-spinner
    *ngIf="btnType === 'link'"
    class="absolute top-1/2 -right-18px -mt-8px"
    [diameter]="15"
  ></app-spinner>
</ng-container>
