import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseUnsubscibeComponent } from '@cm-shared/base-unsubscibe/base-unsubscibe.component';
import { catchError, merge, Observable, of, switchMap, takeUntil } from 'rxjs';
import { InvestorSelect, isNumber, showPercent } from '../../constants';
import { environment } from '@cm-env/environment';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';

@Component({
	selector: 'app-investor-statements',
	templateUrl: './investor-statements.component.html',
	styleUrls: ['./investor-statements.component.scss'],
})
export class InvestorStatementsComponent extends BaseUnsubscibeComponent implements AfterViewInit {
	investors: InvestorSelect[] = [];
	data: any[] = [];
	isLoadingResults = true;
	investor = new FormControl<InvestorSelect>(null);
	filteredOptions: Observable<InvestorSelect[]>;
	displayedColumns: string[] = ['index', 'date', 'description', 'buyRate', 'credit', 'debit', 'yield', 'balance'];
	pageSize = 100;
	resultsLength = 0;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatAutocomplete) auto: MatAutocomplete;

	constructor(private http: HttpClient, private changeDetectorRefs: ChangeDetectorRef) {
		super();
	}

	ngAfterViewInit() {
		this.http
			.get(`${environment.apiUrl}/api/investorids`)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: investors => {
					this.investors = investors as InvestorSelect[];
					this.filteredOptions = this.investor.valueChanges.pipe(
						startWith(''),
						map(value => {
							const filterValue = typeof value === 'string' ? value : value?.name;
							return this.investors.filter(
								inv =>
									inv.name.toLowerCase().includes(filterValue.toLowerCase()) ||
									inv.id.toString().includes(filterValue.toLowerCase())
							);
						})
					);
					this.investor.setValue({
						name: 'TEST',
						id: '20150627000347',
					});
					this.paginator.page.next({ length: 25, pageIndex: 1, pageSize: 25 });
				},
			});

		merge(this.paginator.page, this.auto.optionSelected)
			.pipe(
				startWith({}),
				switchMap(() => {
					if (!this.isLoadingResults && this.investorVal) {
						this.isLoadingResults = true;
						return this.getData(this.investorVal.id, this.paginator.pageIndex + 1).pipe(catchError(() => of(null)));
					} else {
						return of(null);
					}
				}),
				map((result: any) => {
					// Flip flag to show that loading has finished.
					this.isLoadingResults = false;

					if (result === null) {
						return [];
					}
					// Only refresh the result length if there is new data. In case of rate
					// limit errors, we do not want to reset the paginator to zero, as that
					// would prevent users from re-triggering requests.
					this.resultsLength = result.meta.itemCount;
					return result.data;
				})
			)
			.subscribe(data => {
				this.data = data;
				this.changeDetectorRefs.detectChanges();
			});
	}

	getData(investorId: string, page?: number) {
		return this.http.get(
			`${environment.apiUrl}/api/tte/investortransactions/${investorId}?take=${this.pageSize}&page=${page}`
		);
	}

	totals(fieldName: string): number {
		return this.data
			.map(invData => _.get(invData, fieldName))
			.filter(val => val !== '-' && !_.isNil(val) && !_.isNaN(val) && val !== '')
			.reduce((prev, curr) => prev + parseFloat(curr), 0);
	}

	get dayDifference() {
		if (this.data.length > 1) {
			// There should be at least one transaction and "Balance Today"
			return dayjs(_.last(this.data)?.date).diff(this.data[this.data.length - 2]?.date, 'day');
		} else {
			return '-';
		}
	}

	get lastBalance() {
		return _.last(this.data)?.balance;
	}

	get investorVal() {
		return this.investor.value;
	}

	displayFn(inv: InvestorSelect): string {
		return inv && inv.name ? inv.name : '';
	}

	protected readonly showPercent = showPercent;
	protected readonly isNumber = isNumber;
}
