import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { BaseUnsubscibeComponent } from '../../shared/base-unsubscibe/base-unsubscibe.component';

@Component({
	selector: 'app-importer',
	templateUrl: './importer.component.html',
	styleUrls: ['./importer.component.scss'],
})
export class ImporterComponent extends BaseUnsubscibeComponent {
	fileForm = new FormControl('');
	fileData: FormData;
	$progress = new BehaviorSubject(0);
	$importing = new BehaviorSubject(false);
	transactions: number;

	constructor(public http: HttpClient) {
		super();
	}

	fileChange(event: Event) {
		const input = event.target as HTMLInputElement;
		if (!input.files?.length) {
			return;
		}
		const file = input.files[0];
		let formData = new FormData();
		formData.append('file', file, file.name);
		this.fileData = formData;
		this.$progress.next(0);
	}

	uploadFile() {
		this.$importing.next(true);
		this.http
			.post(`${environment.apiUrl}/api/importer/upload`, this.fileData, {
				reportProgress: true,
				observe: 'events',
			})
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: event => {
					console.log(event);
					if (event.type === HttpEventType.UploadProgress) {
						this.$progress.next(Math.round((100 * event.loaded) / event.total));
					} else if (event.type == HttpEventType.Response) {
						this.$importing.next(false);
						this.transactions = (event.body as any).transactions;
					}
				},
				error: error => {
					console.error(error);
					this.$importing.next(false);
				},
			});
	}

	get file() {
		return this.fileForm.value;
	}
}
