import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UiTouchscreenService } from '@cm-core/services/ui-touchscreen.service';
import { isEmptyValue } from '@cm-shared/helpers/is-empty';

import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { TooltipModule } from '../tooltip/tooltip.module';
import { CmSpinnerModule } from '../spinner/spinner.component';
import { InputClearBtnModule } from '../input-clear-btn/input-clear-btn.component';
import { InputErrorModule } from '../input-error/input-error.component';

@Component({
  selector: 'app-mat-form-field-wrap',
  templateUrl: './mat-form-field-wrap.component.html',
})
export class MatFormFieldWrapComponent {
  // required
  @Input() formCtrlName?: string;
  @Input() formCtrl?: AbstractControl;

  // optional
  @Input() htmlInputEl?: HTMLInputElement;

  // tooltip
  @Input() tooltipText?: string;

  // btns
  @Input() showClearBtn?: boolean;
  @Input() showEditBtn?: boolean;
  @Input() showPasswVisibilityBtn?: boolean;

  // input events
  @Input() inputHtmlKeyUp$?: Subject<unknown>;
  @Input() inputHtmlBlur$?: Subject<unknown>;
  @Input() inputHtmlFocus$?: Subject<unknown>;

  // select created from autocomplete
  @Input() isSelect: boolean;
  @Input() isSelectDropdownOpen: boolean;

  @Input() hideErrors: boolean;
  @Input() showLoader: boolean;
  @Input() inputRef: HTMLInputElement;
  @Input() isVisible: boolean;
  @Input() isRightAlignment: boolean;

  @Output() editClicked = new EventEmitter<Event>();

  @HostBinding('class') get classes(): string {
    const val = this.formCtrl?.value;
    const isEmpty = isEmptyValue(val);
    return [
      this.isRightAlignment ? 'ib-mat-form-field-wrap--right-align' : null,
      this.showPasswVisibilityBtn
        ? 'ib-mat-form-field-wrap--with-passw-toggle'
        : null,
      !isEmpty ? 'ib-mat-form-field-wrap--with-value' : null,
      this.showEditBtn ? 'ib-mat-form-field-wrap--with-edit-btn' : null,
      this.showClearBtn ? 'ib-mat-form-field-wrap--with-clear-btn' : null,
    ]
      .filter(i => i)
      .join(' ');
  }

  constructor(public tsS: UiTouchscreenService) {}

  togglePasswVisibility(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    this.htmlInputEl.type =
      this.htmlInputEl.type === 'password' ? 'text' : 'password';
  }
}

@NgModule({
  declarations: [MatFormFieldWrapComponent],
  imports: [
    CommonModule,
    CmSpinnerModule,
    MatButtonModule,
    InputClearBtnModule,
    MatFormFieldModule,
    InputErrorModule,
		InputClearBtnModule,
    TooltipModule,
  ],
  exports: [MatFormFieldWrapComponent],
})
export class MatFormFieldWrapModule {}
