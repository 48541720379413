import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { JOURNAL_LINE } from '../constants';
import { JournalLine } from './store/journal-line.model';

@Injectable({ providedIn: 'root' })
export class JournalLineService extends EntityCollectionServiceBase<JournalLine> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(JOURNAL_LINE, serviceElementsFactory);
	}
}
