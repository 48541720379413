import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@cm-env/environment';
import { IInvestorForSelect } from '@cm-shared/interfaces/investor.intf';
import { IInvestor } from 'credebt-shared';

import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvestorsHttpService {
  constructor(private http: HttpClient) {}

  public fetchListForSelect(): Observable<Array<IInvestorForSelect>> {
		return this.http.get<Array<IInvestorForSelect>>(`${environment.apiUrl}/api/investor/listForSelect`)
	}

	public fetchById(id: string): Observable<IInvestor> {
		return this.http.get<IInvestor>(`${environment.apiUrl}/api/investor/getById/${id}`)
	}
}
