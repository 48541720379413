import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Ledger } from './store/ledger.model';
import { LEDGER } from '../constants';

@Injectable({ providedIn: 'root' })
export class LedgerService extends EntityCollectionServiceBase<Ledger> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(LEDGER, serviceElementsFactory);
	}
}
