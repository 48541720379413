import { ValidatorFn, Validators } from '@angular/forms';


import { FORM_ERRORS } from '@cm-shared/validations/form-control-errors.enum';
import { validatorPattern } from '@cm-shared/validations/validator-pattern';

export const PASSWORD_SPECIFIC_SYMBOLS = '#$@!%&*?';

const passwSpecSymbolRegExp = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `[A-Za-z\d${PASSWORD_SPECIFIC_SYMBOLS}]$`,
);

export const passwordValidators: Array<ValidatorFn> = [
  Validators.minLength(8),
  Validators.maxLength(100),
  Validators.required,
  validatorPattern(/\d/, { [FORM_ERRORS.HAS_NUMBER]: true }),
  validatorPattern(/[A-Z]/, { [FORM_ERRORS.HAS_CAPITALCASE]: true }),
  validatorPattern(/[a-z]/, { [FORM_ERRORS.HAS_LOWERCASE]: true }),
  validatorPattern(passwSpecSymbolRegExp, {
    [FORM_ERRORS.HAS_SPECIFIC_SYMBOL]: true,
  }),
];
