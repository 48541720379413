export enum FORM_ERRORS {
  // standart angular
  EMAIL = 'email',
  REQUIRED = 'required',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  MIN = 'min',
  MAX = 'max',
  PATTERN = 'pattern',
  REQUIRED_TRUE = 'requiredTrue',

  // custom errors
  EMAIL_NOT_AVAILABLE = 'emailNotAvailable',
  HAS_NUMBER = 'hasNumber',
  HAS_CAPITALCASE = 'hasCapitalCase',
  HAS_LOWERCASE = 'hasSmallCase',
  HAS_SPECIFIC_SYMBOL = 'hasSpecificSymbol',
  CONFIRM_PASSW_VALIDATOR = 'confirmPasswordValidator',
  IBAN_INVALID = 'ibanWrongFormat',
  PASSWORD_MATCHING = 'passwordMatching',
  PHONE = 'invalidItiPhone',
  ONLY_NUMBERS = 'onlyNumbers',
  PHONE_NO_LETTERS = 'phoneNoLetters',
  LETTERS_FORBIDDEN = 'lettersForbiden',
  SPECIAL_CHARACHTERS_FORBIDDEN = 'specificCharachtersForbidden',
  ATTACHED_DOCS_REQUIRED = 'attachedDocsRequired',
  VAT = 'VAT',
  PPS = 'PPS',
  PHONE_NOT_AVAILABLE = 'phoneNotAvailable',
	NOT_ZERO = 'notZero'
}
