import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldWrapModule } from './modules/mat-form-field-wrap/mat-form-field-wrap.component';
import { PhoneModule } from './modules/phone/phone.component';
import { InvestorSelectorComponent } from './components/investor-selector/investor-selector.component';
import { InputErrorModule } from './modules/input-error/input-error.component';
import { BtnWrapModule } from './modules/btn-wrap/btn-wrap.component';

const modules = [
	CommonModule, ReactiveFormsModule, FormsModule,
	MaterialModule, AgGridModule, MatFormFieldWrapModule,
	PhoneModule, InputErrorModule, BtnWrapModule
]

const components = [InvestorSelectorComponent]
@NgModule({
	declarations: [...components],
	imports: [...modules],
	exports: [...modules, ...components]
})
export class SharedModule {}
