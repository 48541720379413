import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JOURNAL } from '../../constants';
import { JournalService } from '../journal.service';
import { Journal } from '../store/journal.model';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { takeUntil } from 'rxjs';
import { JournalDialogComponent } from '../journal-dialog/journal-dialog.component';

@Component({
	selector: 'app-accounts',
	templateUrl: './journals.component.html',
	styleUrls: ['./journals.component.scss'],
})
export class JournalsComponent extends BaseListComponent implements OnInit {
	loading$ = this.journalService.loading$;
	loaded$ = this.journalService.loaded$;
	journals$ = this.journalService.entities$;
	journals: Journal[];
	displayedColumns: string[] = ['name', 'code', 'status', 'type', 'taxType', 'description', 'class', 'actions'];

	constructor(public journalService: JournalService, dialog: MatDialog, snackBar: MatSnackBar) {
		super(journalService, dialog, snackBar);
		this.dialogClass = JournalDialogComponent;
		this.journals$.pipe(takeUntil(this.unsubscribe$)).subscribe(journals => {
			this.journals = journals;
			this.dataSource = new MatTableDataSource(this.journals);
			this.dataSource.sort = this.sort;
		});
		this.journalService.getAll();
	}

	ngOnInit() {
		this.prepareEventNotifications(JOURNAL, this.journalService.entityActions$);
	}
}
