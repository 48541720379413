import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { debounceTime, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map, shareReplay } from 'rxjs/operators';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';

import * as Sentry from '@sentry/angular-ivy';
import { ApiService } from '../.api-client/services/api.service';
import { Store } from '@ngrx/store';
import { loadUserInfo } from './user/store/user.actions';
import { selectIsAuthenticated, selectUserData } from './user/store/user.selectors';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map(result => result.matches),
		shareReplay()
	);
	isAuthenticated$ = this.store.select(selectIsAuthenticated);
	userData$ = this.store.select(selectUserData);

	constructor(
		private breakpointObserver: BreakpointObserver,
		public oidcSecurityService: OidcSecurityService,
		private eventService: PublicEventsService,
		private api: ApiService,
		private store: Store,
		private router: Router
	) {}

	ngOnInit() {
		this.eventService
			.registerForEvents()
			.pipe(
				filter(notification => notification.type === EventTypes.UserDataChanged),
				debounceTime(1000)
			)
			.subscribe((notification: any) => {
				if (notification.value.userData?.oid) {
					this.isAuthenticated$.subscribe(isAuthenticated => {
						if (!isAuthenticated) {
							this.store.dispatch(
								loadUserInfo({
									oid: notification.value.userData?.oid,
									name: notification.value.userData?.name,
									email: notification.value.userData?.email,
								})
							);
						}
					});
					Sentry.setUser({
						email: notification.value.userData?.email,
						username: notification.value.userData?.name,
					});
				}
			});
	}

	logout() {
		this.oidcSecurityService.logoff().subscribe(result => console.log(result));
	}

	public isActive(base: string): boolean {
		return this.router.url.includes(`/${base}`);
	}
}
