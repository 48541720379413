import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit {
  public isTouchScreen: boolean;
  @Input() showLikeTextHint = true;
  @Input() hideDefIcon?: boolean;
  @Input() hoverPosition?: TooltipPosition;
  @Input() text?: string;
  @Input() customTmpl?: TemplateRef<HTMLElement>;

  @ViewChild('modalTmpl') modalTmpl: TemplateRef<HTMLElement>;

  @HostBinding('class') get classes(): string {
    return this.showLikeTextHint ? '' : 'ib-tooltip-by-hover';
  }

  public position: TooltipPosition;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.isTouchScreen =
      'ontouchstart' in window ||
      navigator?.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (navigator as any)?.msMaxTouchPoints > 0;
    this.position = this.hoverPosition || 'below';
  }

  showModal(): void {
    this.dialog.open(this.modalTmpl, {
      width: '300px',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
    });
  }
}
