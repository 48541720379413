<div *ngIf="formCtrl?.errors || customErrors">
  <div
    [@inOpacityFromTop]
    class="!flex grow overflow-hidden justify-between items-start"
  >
    <i
      class="material-icons relative mr-8px top-1px material-icons text-error! text-xs"
      >warning_amber</i
    >

    <div class="grow  text-error! text-xs ml-2">
      <ng-container *ngIf="formCtrl?.errors">
        <ng-container
          *ngIf="isRequiredError && !hideErrors?.includes(FORM_ERRORS.REQUIRED)"
          >Please fill out this mandatory field.
        </ng-container>

        <ng-container *ngIf="!isRequiredError">
          <ng-container *ngIf="isEmailFormatError"
            >The email address has an incorrect format.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.EMAIL_NOT_AVAILABLE] &&
              !hideErrors?.includes(FORM_ERRORS.EMAIL_NOT_AVAILABLE)
            "
            >The email is already registered.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.MIN_LENGTH] &&
              !hideErrors?.includes(FORM_ERRORS.MIN_LENGTH)
            "
            >Minimum of&nbsp;{{
              formCtrl.errors[FORM_ERRORS.MIN_LENGTH].requiredLength
            }}&nbsp;characters.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.MAX_LENGTH] &&
              !hideErrors?.includes(FORM_ERRORS.MAX_LENGTH)
            "
            >Maximum of&nbsp;{{
              formCtrl.errors[FORM_ERRORS.MAX_LENGTH].requiredLength
            }}&nbsp;characters.</ng-container
          >

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.MIN] &&
              !hideErrors?.includes(FORM_ERRORS.MIN)
            "
            >Min value not met: Minimum value is&nbsp;{{
              formCtrl.errors[FORM_ERRORS.MIN].min
            }}.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.MAX] &&
              !hideErrors?.includes(FORM_ERRORS.MAX)
            "
            >Max value exceeded: Maximum value is&nbsp;{{
              formCtrl.errors[FORM_ERRORS.MAX].max
            }}&nbsp;.
          </ng-container>

          <ng-container
            *ngIf="
              !isEmailInput &&
              formCtrl.errors[FORM_ERRORS.PATTERN] &&
              !hideErrors?.includes(FORM_ERRORS.PATTERN)
            "
          >
            Wrong Pattern.
          </ng-container>

          <ng-container
            *ngIf="
              !isEmailInput &&
              formCtrl.errors[FORM_ERRORS.HAS_NUMBER] &&
              !hideErrors?.includes(FORM_ERRORS.HAS_NUMBER)
            "
          >
            Must contain at least one number.
          </ng-container>
          <ng-container
            *ngIf="
              !isEmailInput &&
              formCtrl.errors[FORM_ERRORS.HAS_CAPITALCASE] &&
              !hideErrors?.includes(FORM_ERRORS.PATTERN)
            "
          >
            Must contain uppercase symbols.
          </ng-container>
          <ng-container
            *ngIf="
              !isEmailInput &&
              formCtrl.errors[FORM_ERRORS.HAS_LOWERCASE] &&
              !hideErrors?.includes(FORM_ERRORS.HAS_LOWERCASE)
            "
          >
            Must contain lowercase symbols.
          </ng-container>
          <ng-container
            *ngIf="
              !isEmailInput &&
              formCtrl.errors[FORM_ERRORS.HAS_SPECIFIC_SYMBOL] &&
              !hideErrors?.includes(FORM_ERRORS.HAS_SPECIFIC_SYMBOL)
            "
          >
            Must contain at least one specific symbol:
            {{ PASSWORD_SPECIFIC_SYMBOLS }}.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.REQUIRED_TRUE] &&
              !hideErrors?.includes(FORM_ERRORS.REQUIRED_TRUE)
            "
          >
            Please fill out this mandatory field.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.PHONE] &&
              !hideErrors?.includes(FORM_ERRORS.PHONE)
            "
          >
            {{
              'input.phone.iti-err-enums.' + formCtrl.errors[FORM_ERRORS.PHONE]
            }}.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.IBAN_INVALID] &&
              !hideErrors?.includes(FORM_ERRORS.IBAN_INVALID)
            "
          >
            <ng-container
              *ngFor="let err of formCtrl.errors[FORM_ERRORS.IBAN_INVALID]"
            >
              {{ err }}.&nbsp;
            </ng-container>
          </ng-container>

          <ng-container *ngIf="formCtrl.errors[FORM_ERRORS.PASSWORD_MATCHING]">
            Confirm passwords should be identical to Password.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.LETTERS_FORBIDDEN] &&
              !hideErrors?.includes(FORM_ERRORS.LETTERS_FORBIDDEN)
            "
          >
            Letters forbidden.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.ATTACHED_DOCS_REQUIRED] &&
              !hideErrors?.includes(FORM_ERRORS.ATTACHED_DOCS_REQUIRED)
            "
          >
            <ng-container
              *ngIf="formCtrl.errors[FORM_ERRORS.ATTACHED_DOCS_REQUIRED] === 1"
              >Please attach document</ng-container
            >
            <ng-container
              *ngIf="formCtrl.errors[FORM_ERRORS.ATTACHED_DOCS_REQUIRED] > 1"
              >Please attach documents</ng-container
            >
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.ONLY_NUMBERS] &&
              !hideErrors?.includes(FORM_ERRORS.ONLY_NUMBERS)
            "
          >
            Only numbers.
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.VAT] &&
              !hideErrors?.includes(FORM_ERRORS.VAT)
            "
          >
            Wrong VAT format, must be - 8 characters where the second will
            sometimes be a letter and the last is always a letter
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.PPS] &&
              !hideErrors?.includes(FORM_ERRORS.PPS)
            "
          >
            Wrong PPS format, must be - 7 numbers followed by either one or two
            letters
          </ng-container>

          <ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.PHONE_NOT_AVAILABLE] &&
              !hideErrors?.includes(FORM_ERRORS.PHONE_NOT_AVAILABLE)
            "
          >
            Phone already registered
          </ng-container>

					<ng-container
            *ngIf="
              formCtrl.errors[FORM_ERRORS.NOT_ZERO] &&
              !hideErrors?.includes(FORM_ERRORS.NOT_ZERO)
            "
          >
            Any positive or negative number, but not 0.
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="customErrors">
        {{ customErrors }}
      </ng-container>
    </div>
  </div>
</div>
