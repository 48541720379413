import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { EntityAction, EntityCollectionServiceBase, EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { DEFAULT_MODAL_WIDTH, GENERIC_ERROR_MSG, TRANSACTION_MODEL } from '../../constants';
import { MatTableDataSource } from '@angular/material/table';
import { BaseUnsubscibeComponent } from '../base-unsubscibe/base-unsubscibe.component';

@Component({
	template: ``,
	selector: 'app-base-list',
})
export abstract class BaseListComponent extends BaseUnsubscibeComponent implements AfterViewInit {
	dialogRef: MatDialogRef<any>;
	dialogClass: ComponentType<any>;
	dataSource: MatTableDataSource<any>;
	@ViewChild(MatSort) sort: MatSort;
	items: any[];

	protected constructor(
		protected entityService: EntityCollectionServiceBase<any>,
		protected dialog: MatDialog,
		protected snackBar: MatSnackBar
	) {
		super();
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	prepareEventNotifications(model: string, actions$: Observable<EntityAction<any>>) {
		actions$
			.pipe(ofEntityType(model), ofEntityOp([EntityOp.SAVE_ADD_ONE_SUCCESS, EntityOp.SAVE_UPDATE_ONE_SUCCESS]))
			.subscribe(state => {
				this.dialogRef?.close();
				this.snackBar.open('Object saved successfully!', 'Dismiss', {
					duration: 5000,
				});
			});

		actions$.pipe(ofEntityType(TRANSACTION_MODEL), ofEntityOp([EntityOp.SAVE_DELETE_ONE_SUCCESS])).subscribe(state => {
			this.snackBar.open('Object deleted successfully!', 'Dismiss', {
				duration: 5000,
			});
		});
		actions$
			.pipe(ofEntityType(TRANSACTION_MODEL), ofEntityOp([EntityOp.SAVE_DELETE_ONE_ERROR, EntityOp.SAVE_ADD_ONE_ERROR]))
			.subscribe(state => {
				this.snackBar.open(state.payload.data.error.message || GENERIC_ERROR_MSG, 'Dismiss', {
					duration: 5000,
				});
			});
	}

	refresh() {
		this.entityService.getAll();
	}

	delete($event: Event, id: string) {
		$event.stopPropagation();
		this.entityService.delete(id, { isOptimistic: false });
		return false;
	}

	edit($event: Event, id: string) {
		$event.stopPropagation();
		this.dialogRef = this.dialog.open(this.dialogClass, {
			data: { id },
			width: DEFAULT_MODAL_WIDTH,
		});
		return false;
	}

	newEntity() {
		this.dialogRef = this.dialog.open(this.dialogClass, {
			width: DEFAULT_MODAL_WIDTH,
		});
	}
}
