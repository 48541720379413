import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('userState');

export const selectIsAuthenticated = createSelector(selectUserState, (state: UserState) => state.isAuthenticated);

export const selectUserData = createSelector(selectUserState, (state: UserState) => {
	return {
		...state.data,
		initials: state.data.name
			.split(' ')
			.map((namePart: string) => namePart[0])
			.join(''),
	};
});
