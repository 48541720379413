import { createReducer, on } from '@ngrx/store';
import { loadUserInfo } from './user.actions';

export interface UserState {
	isAuthenticated: boolean;
	xeroExpires: Date | null;
	data: {
		oid: string;
		name: string;
		email: string;
	};
}

export const initialState: UserState = {
	isAuthenticated: false,
	xeroExpires: null,
	data: {
		oid: '',
		name: '',
		email: '',
	},
};

export const userReducer = createReducer(
	initialState,
	on(loadUserInfo, (state, { oid, name, email }) => ({
		...state,
		isAuthenticated: true,
		data: {
			oid: oid,
			name: name,
			email: email,
		},
	}))
);
