import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { INVESTOR_DATA } from '../constants';
import { InvestorData } from './store/investor-data.model';

@Injectable({ providedIn: 'root' })
export class InvestorDataService extends EntityCollectionServiceBase<InvestorData> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super(INVESTOR_DATA, serviceElementsFactory);
	}
}
