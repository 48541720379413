import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { XeroComponent } from './tools/xero/xero.component';
import { XeroCallbackComponent } from './tools/xero-callback/xero-callback.component';
import { LedgersComponent } from './chart-of-accounts/ledgers/ledgers.component';
import { JournalsComponent } from './chart-of-accounts/journals/journals.component';
import { LedgerDetailsComponent } from './chart-of-accounts/ledger-details/ledger-details.component';
import { TransactionModelsComponent } from './transactions/transaction-models/transaction-models.component';
import { TransactionModelDetailsComponent } from './transactions/transaction-model-details/transaction-model-details.component';
import { TransactionFieldsComponent } from './transactions/transaction-fields/transaction-fields.component';
import { ImporterComponent } from './transactions/importer/importer.component';
import { InvestorDataComponent } from './transactions/investor-data/investor-data.component';
import { InvestorTransactionsComponent } from './transactions/investor-transactions/investor-transactions.component';
import { InvestorStatementsComponent } from './statements/investor-statements/investor-statements.component';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'journals',
		component: JournalsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'models',
		component: TransactionModelsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'models/:id',
		component: TransactionModelDetailsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'fields',
		component: TransactionFieldsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'importer',
		component: ImporterComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'investor-data',
		component: InvestorDataComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'investor-transactions',
		component: InvestorTransactionsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'investor-statement',
		component: InvestorStatementsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'ledgers',
		component: LedgersComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'ledgers/:id',
		component: LedgerDetailsComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'xero',
		component: XeroComponent,
		canActivate: [AutoLoginAllRoutesGuard],
	},
	{
		path: 'xeroCallback',
		component: XeroCallbackComponent,
	},
	{
		path: 'assets',
		loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule),
	},

	{ path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
