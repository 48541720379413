import * as _ from 'lodash';

export const GENERIC_ERROR_MSG = 'Something went wrong. Please try again or contact the administrator.';

export const MASTER_LEDGER = 'Master Ledger';

export const LEDGER = 'Ledger';
export const JOURNAL = 'Journal';
export const TRANSACTION_MODEL = 'TransactionModel';
export const TRANSACTION_FIELD = 'TransactionField';
export const JOURNAL_LINE = 'JournalLine';
export const INVESTOR_DATA = 'InvestorData';

export const DEFAULT_MODAL_WIDTH = '600px';

export function isNumber(val: any): boolean {
	return typeof val === 'number' || (!isNaN(parseFloat(val)) && isFinite(val));
}

function _showPercent(value: number) {
	let percent = (value * 100).toString();
	if (isNumber(value)) {
		if (percent.indexOf('.') === -1) {
			percent = percent + '.';
		}
		return percent.padEnd(5, '0').slice(0, 5) + '%';
	} else return '';
}

export const showPercent = _.memoize(_showPercent);

export class InvestorSelect {
	id?: string;
	name: string;

	toString() {
		return this.name;
	}
}
